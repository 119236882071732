import React, { useState } from "react";
import {
  AdjustmentsHorizontalIcon,
  CheckBadgeIcon,
} from "@heroicons/react/16/solid";
import MatchSetting from "./MatchSetting";
import MatchCard from "../../card/Match";


const FinalizedSingleElimination = ({ drawId, groups, admin }) => {
  const [editMatch, setEditMatch] = useState(false);
  const [match, setMatch] = useState("");

  return (
    <>
      {editMatch && (
        <MatchSetting match={match} admin={admin} setEditMatch={setEditMatch} />
      )}
      <div
        className={`grid grid-cols-${
          groups?.filter((group) => group.type === "SE").length
        } items-center overflow-scroll`}
        style={{
          gridTemplateColumns: `repeat(${
            groups?.filter((group) => group.type === "SE").length
          }, minmax(480px, 1fr))`,
        }}
      >
        {(() => {
          if (!groups || groups.length === 0) {
            return <div>No groups available</div>;
          }

          const renderRound = (group, groupIndex) => {
            const totalMatches = Math.pow(
              2,
              groups.filter((group) => group.type === "SE").length -
                groupIndex -
                1
            );
            const sortedMatches = group.matches
              ? [...group.matches].sort((a, b) => a?.number - b?.number)
              : [];

            const displayMatches = Array.from({ length: totalMatches }, () => ({
              match: null,
            }));

            sortedMatches.forEach((match) => {
              if (match.number < displayMatches.length) {
                displayMatches[match.number] = match;
              }
            });

            const matches = [];
            for (let i = 0; i < displayMatches.length; i++) {
              matches.push(displayMatches[i] || { match: null });
            }

            return (
              <div key={groupIndex} className={`flex flex-col h-[100%]`}>
                {matches.map((match, i) => (
                  <div
                    key={i}
                    className="grid grid-cols-12 items-center justify-center flex-1"
                  >
                    {groupIndex !== 0 && (
                      <span className=" h-[3px] bg-green-700 col-span-1"></span>
                    )}
                    <div
                      className={`${
                        groupIndex === 0 ? "col-span-11" : "col-span-10"
                      } w-full items-center `}
                    >
                      <div
                        key={i}
                        className={`w-full border shadow border-gray-300 rounded my-10 `}
                      >
                        <div className="bg-blue-800 py-1 px-3 w-full border-2 border-blue-800 flex justify-between">
                        <div className="flex gap-5">
                          <div className="flex items-center gap-0.5 text-white rounded text-xs font-bold">
                            {match?.matchTables?.length < 1 ? (
                              "No Table"
                            ) : (
                              <>
                                <span className="mr-2">TABLE</span>

                                {match?.matchTables
                                  ?.slice() // Create a shallow copy of the array
                                  .sort((a, b) => a.index - b.index) // Sort the copied array
                                  .map((table, i) => (
                                    <span key={i} className="">
                                      {table.index}{" "}
                                      {match?.matchTables?.length !== i + 1 &&
                                        ","}
                                    </span>
                                  ))}
                              </>
                            )}
                          </div>
                          <div className="flex items-center text-white text-xs font-medium">
                            {match?.group?.stage === "RR" ? (
                              <span>GROUP {match?.group?.number + 1}</span>
                            ) : (
                              <span>{match?.group?.stage}</span>
                            )}
                          </div>
                          {(match?.startDate || match?.startTime) && match?.draw?.scheduleMatches && (
                            <div className=" flex gap-2 items-center text-white font-medium text-xs">
                              <div>{match?.startTime}</div>
                              <div>
                                {match?.startDate?.split("-")[1]}
                                {match?.startDate && "/"}
                                {match?.startDate?.split("-")[2]}
                              </div>
                            </div>
                          )}
                          {match?.verified && (
                            <div title="VERIFIED" className="flex text-xs items-center">
                              <CheckBadgeIcon
                                className=" text-green-400"
                                height={16}
                                width={16}
                              />
                            </div>
                          )}
                          </div>
                          {match?.matchPlayers?.length === 2 && admin ? (
                            <button
                              disabled={match?.matchPlayers?.length < 2}
                              onClick={() => {
                                setEditMatch(true);
                                setMatch(match);
                              }}
                              className="border p-0.5 border-white rounded "
                            >
                              <AdjustmentsHorizontalIcon
                                style={{ color: "white" }}
                                height={16}
                                width={16}
                              />
                            </button>
                          ) : match?.matchPlayers?.length === 1 ? (
                            <div className="text-xs font-medium text-white">
                              WALKOVER
                            </div>
                          ) : (
                            <div className="text-xs font-medium text-white">
                              TBD
                            </div>
                          )}
                        </div>
                        <MatchCard match={match} />
                      </div>
                    </div>
                    {groupIndex !==
                      groups.filter((group) => group.type === "SE").length -
                        1 && (
                      <div className="col-span-1 flex flex-col h-[100%] justify-end items-end">
                        {i % 2 !== 0 ? (
                          <span className=" w-[3px] bg-green-700 h-full"></span>
                        ) : (
                          <span className=" w-[3px]  h-full"></span>
                        )}

                        <span className=" h-[5px] bg-green-700 w-full"></span>
                        {i % 2 === 0 ? (
                          <span className=" w-[3px] bg-green-700 h-full"></span>
                        ) : (
                          <span className=" w-[3px]  h-full"></span>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            );
          };

          return groups
            .filter((group) => group.type === "SE")
            .slice()
            .sort((a, b) => a.number - b.number)
            .map((group, groupIndex) => (
              <div key={groupIndex} className="h-[100%]">
                {renderRound(group, groupIndex)}
              </div>
            ));
        })()}
      </div>
    </>
  );
};

export default FinalizedSingleElimination;
