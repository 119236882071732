import {
  AdjustmentsHorizontalIcon,
  TrophyIcon,
} from "@heroicons/react/16/solid";
import React, { useState } from "react";
import MatchSetting from "../sections/draws/MatchSetting";
import MatchCard from "../card/Match";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";

const Matches = ({ matches, admin, playerId }) => {
  const [editMatch, setEditMatch] = useState(false);
  const [match, setMatch] = useState("");
  return (
    <>
      {editMatch && (
        <MatchSetting match={match} admin={admin} setEditMatch={setEditMatch} />
      )}
      <div className="flex flex-col items-start justify-start p-3 mb-3 mx-3 rounded-lg">
        {matches?.map((match, i) => (
          <div key={i} className={` w-full items-center `}>
            <div
              className={`w-full border shadow border-gray-300 rounded my-10 `}
            >
              <div className="bg-blue-800 py-1 px-3 w-full border-2 border-blue-800 text-[10px] sm:text-sm flex justify-between">
                <div className="flex gap-5">
                  <div className="flex items-center gap-0.5 text-white rounded font-bold">
                    {match?.matchTables?.length < 1 ? (
                      "No Table"
                    ) : (
                      <>
                        <span className="mr-2">TABLE</span>

                        {match?.matchTables
                          ?.slice() // Create a shallow copy of the array
                          .sort((a, b) => a.index - b.index) // Sort the copied array
                          .map((table, i) => (
                            <span key={i} className="">
                              {table.index}{" "}
                              {match?.matchTables?.length !== i + 1 && ","}
                            </span>
                          ))}
                      </>
                    )}
                    <div className="ml-5 flex gap-1">
                      <span>{match?.event?.eventName}</span>
                      <span className="font-bold">.</span>
                      <span>{match?.draw?.drawName}</span>
                      <span className="font-bold">.</span>
                      {match?.group?.stage === "RR" ? (
                        <span>GROUP {match?.group?.number + 1}</span>
                      ) : (
                        <span>{match?.group?.stage}</span>
                      )}
                    </div>
                  </div>
                  {(match?.startDate || match?.startTime) &&
                    match?.draw?.scheduleMatches && (
                      <div className=" flex gap-2 items-center text-white font-medium">
                        <div>{match?.startTime}</div>
                        <div>
                          {match?.startDate?.split("-")[1]}
                          {match?.startDate && "/"}
                          {match?.startDate?.split("-")[2]}
                        </div>
                      </div>
                    )}
                  {match?.verified && (
                    <div title="VERIFIED" className="flex text-xs items-center">
                      <CheckBadgeIcon
                        className=" text-green-400"
                        height={20}
                        width={20}
                      />
                    </div>
                  )}
                </div>

                {
                  match?.result === "" &&
                  match?.group?.type === "RR" && 
                  match?.draw?.roundRobinSettings?.activateSE ? (
                  <div className=" font-medium text-white">SKIPPED</div>
                ) :
                  match?.matchPlayers?.length === 2 ? (
                  <>
                    {((admin &&  ((match?.group?.type === "RR" && match?.draw?.roundRobinSettings?.activateSE) ? false : true ))||
                      (!match.verified &&
                        match.matchPlayers.some(
                          (player) =>
                            player.id === playerId &&
                            (match.group.type === "RR"
                              ? !match?.draw?.roundRobinSettings?.activateSE
                              : true)
                        ))) && (
                      <div>
                        <button
                          disabled={match?.matchPlayers?.length < 2}
                          onClick={() => {
                            setEditMatch(true);
                            setMatch(match);
                          }}
                          className="border p-0.5 border-white rounded "
                        >
                          <AdjustmentsHorizontalIcon
                            style={{ color: "white" }}
                            height={16}
                            width={16}
                          />
                        </button>
                      </div>
                    )}
                  </>
                ) : match?.matchPlayers?.length === 1 ? (
                  <div className=" font-medium text-white">WALKOVER</div>
                ) : (
                  <div className=" font-medium text-white">TBD</div>
                )}
              </div>

              <MatchCard match={match}/>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Matches;
