export const playersColumns = [
  {
    Header: "First Name",
    accessor: "firstName",
  },
  {
    Header: "Last Name",
    accessor: "lastName",
  },
  {
    Header: "Sex",
    accessor: "sex",
  },
  {
    Header: "Club",
    accessor: "homeClub",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Address",
    accessor: "homeAddress",
  },
  {
    Header: "Rating",
    accessor: "rating",
  },
  {
    Header: "Membership",
    accessor: "memberShipExpiryDate",
  },
];
