import React from "react";
import { useSelector } from "react-redux";
import { useGetPlayerEntriesQuery } from "../../../redux/features/api/apiSlice";
import loading from "../../../images/loading-image.svg";

const PlayerEntriesTab = ({ player }) => {
  const { isFetching } = useGetPlayerEntriesQuery(player?.id, {
    skip: !player?.id,
  });
  const { playerEntries } = useSelector((state) => state.players);

  return (
    <>
      {isFetching && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay">
          <div className="  relative mx-3">
            <img className="" src={loading} alt="" />
          </div>
        </div>
      )}
      <div className="relative overflow-x-auto ">
        <div className="my-5 flex justify-between ">
          <div className="text-sm text-gray-500">
            {playerEntries?.length} entries found
          </div>
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 shadow-md sm:rounded-lg">
          <thead className="text-xs text-gray-700 uppercase bg-gray-200">
            <tr>
              <th scope="col" className="px-6 py-3 w-[25%]">
                Player
              </th>
              <th scope="col" className="px-6 py-3 w-[10%]">
                Member ID
              </th>
              <th scope="col" className="px-6 py-3 w-[12%]">
                Tournament
              </th>
              <th scope="col" className="px-6 py-3 w-[18%]">
                Events
              </th>
              <th scope="col" className="px-2 py-3 w-[5%]">
                Total
              </th>
              <th scope="col" className="px-2 py-3 w-[5%]">
                Paid
              </th>
              <th scope="col" className="px-2 py-3 w-[5%]">
                Remaining
              </th>
              <th scope="col" className="px-6 py-3 w-[18%]">
                Notes
              </th>
            </tr>
          </thead>
          <tbody>
            {playerEntries?.map((entry, i) => (
              <tr key={i} className="odd:bg-gray-50  even:bg-gray-100 border-b">
                <th
                  scope="row"
                  className="px-1 py-1 font-medium text-gray-900 whitespace-nowrap "
                >
                  <div className="bg-white rounded-xl px-3">
                    <div className="flex gap-3">
                      <div className="text-[10px] text-gray-500">
                        {player?.rating}
                      </div>
                      {player?.club && (
                        <div className="text-[10px] text-gray-500 font-medium">
                          {player?.club?.clubName},{" "}
                          {player?.club?.region},{" "}
                          {player?.club?.state}
                        </div>
                      )}
                    </div>

                    <div className="text-lg">
                      {player?.firstName}, {player?.lastName}
                    </div>
                  </div>
                </th>
                <td className="px-2 py-4">
                  <div
                    className={`text-xs px-1 ${
                      player?.memberId
                        ? "bg-blue-600 text-white"
                        : "bg-yellow-200"
                    } text-center`}
                  >
                    {player?.memberId !== null
                      ? player?.memberId
                      : player?.tempId}
                  </div>
                </td>
                <td className="px-2 py-4">
                  <div className="text-xs px-1 bg-blue-200 text-center">
                    {entry?.events[0]?.tournament?.tournamentName}
                  </div>
                </td>
                <td className="px-6 py-1">
                  {" "}
                  <div className="flex flex-wrap gap-1">
                    {entry?.events?.map((event, i) => (
                      <div
                        key={i}
                        className="text-xs bg-gray-300 rounded-sm px-3"
                      >
                        {event?.eventName}
                      </div>
                    ))}
                  </div>
                </td>
                <td className="px-2 py-4">
                  <div className="font-medium">
                    $
                    {(
                      (Number(entry?.tournamentFee) || 0) +
                      entry?.events?.reduce(
                        (sum, event) => sum + (Number(event?.eventFee) || 0),
                        0
                      ) +
                      entry?.charges?.reduce(
                        (sum, charge) => sum + (Number(charge?.charge) || 0),
                        0
                      ) +
                      (Number(entry?.membershipFee) || 0)
                    ).toFixed(2)}
                  </div>
                </td>
                <td className="px-2 py-4">
                  <div className="font-medium">
                    $
                    {entry?.payments?.reduce(
                      (sum, payment) => sum + (Number(payment?.payment) || 0),
                      0
                    ) +
                      entry?.discounts?.reduce(
                        (sum, discount) =>
                          sum + (Number(discount?.discount) || 0),
                        0
                      )}
                  </div>
                </td>
                <td className="px-2 py-4">
                  <div className="font-medium text-red-600">
                    $
                    {(
                      (Number(entry?.tournamentFee) || 0) +
                      entry?.events?.reduce(
                        (sum, event) => sum + (Number(event?.eventFee) || 0),
                        0
                      ) +
                      entry?.charges?.reduce(
                        (sum, charge) => sum + (Number(charge?.charge) || 0),
                        0
                      ) +
                      (Number(entry?.membershipFee) || 0) -
                      entry?.payments?.reduce(
                        (sum, payment) => sum + (Number(payment?.payment) || 0),
                        0
                      ) -
                      entry?.discounts?.reduce(
                        (sum, discount) =>
                          sum + (Number(discount?.discount) || 0),
                        0
                      )
                    ).toFixed(2)}
                  </div>
                </td>
                <td className="px-3 py-1 text-[9px] whitespace-nowrap space-y-[2px]">
                  {entry?.notes?.selfRegistered && (
                    <div className="bg-gray-400 text-center self-center text-white  rounded-sm px-1">
                      SELF REGISTERED
                    </div>
                  )}
                  {entry?.notes?.newMember && (
                    <div className="bg-yellow-400 text-center self-center text-white  rounded-sm px-1">
                      NEW MEMBER
                    </div>
                  )}
                  {entry?.notes?.membershipSold && (
                    <div className="bg-blue-400 text-center self-center text-white  rounded-sm px-1">
                      MEMBERSHIP SOLD
                    </div>
                  )}
                  {entry?.notes?.membershipExpired && (
                    <div className="bg-red-400 text-center self-center text-white  rounded-sm px-1">
                      MEMBERSHIP EXPIRED
                    </div>
                  )}
                  {entry?.notes?.noMembership && (
                    <div className="bg-orange-400 text-center self-center text-white  rounded-sm px-1">
                      NO MEMBERSHIP
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PlayerEntriesTab;
