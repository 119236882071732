import React, { useEffect, useState } from "react";
import { playersColumns } from "../../../constants/columns";
import AddPlayerForm from "./AddPlayerForm";
import { useGetAllPlayerQuery } from "../../../redux/features/api/apiSlice";
import { useSelector } from "react-redux";
const PlayersPage = () => {
  const [showForm, setShowForm] = useState(false);
 useGetAllPlayerQuery()
  const {players} = useSelector((state) => state.players);

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const columns = React.useMemo(() => playersColumns, []);

  return (
    <>
      {showForm ? (
        <AddPlayerForm handleCloseForm={handleCloseForm} />
      ) : (
        <div className="flex flex-col items-start justify-start w-full">
          <h1>Events Page</h1>
          <div className="flex flex-row items-center justify-end w-full my-2">
            <button
              onClick={() => setShowForm(true)}
              className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Add New Event
            </button>
          </div>

          {/* table */}
          {/* <Table columns={columns} data={players} actions={[]} /> */}
        </div>
      )}
    </>
  );
};

export default PlayersPage;
