import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";


export const initialState = {
  clubs: [],
};

export const clubSlice = createSlice({
  name: "club",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getAllClubs.matchFulfilled,
      (state, action) => {
        state.clubs = action.payload.data;
      }
    );
  },
});

export default clubSlice.reducer;
