import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FormFieldError from "../../../components/commons/formFeildError.";
import { teamInitialState } from "../../../redux/features/teamSlice";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useSearchPlayerQuery } from "../../../redux/features/api/apiSlice";

const AddTeamForm = ({ handleCloseForm }) => {
  const [showLocation, setShowLocation] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [selectedPlayer, setSeclectedPlayer] = useState("");
  const [error, setError] = useState("");

  const { data, refetch } = useSearchPlayerQuery(searchTerm, {
    skip: !isFocused || searchTerm === "",
  });
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: teamInitialState,
  });

  const options = [];

  const onSubmit = (data) => {
    console.log(data);
  };

  useEffect(() => {
    if (isFocused && searchTerm) {
      // Refetch only if the searchTerm is not empty and input is focused
      const debounced = setTimeout(() => {
        refetch(); // Trigger refetch for the query
      }, 300);
  
      return () => clearTimeout(debounced); // Cleanup
    }
  }, [searchTerm, isFocused, refetch]);

  useEffect(() => {
    if (data) {
      setFilteredPlayers(data.data);
    } else {
      setFilteredPlayers([]);
    }
  }, [data]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="p-8 space-y-4">
        <div className="flex flex-row items-center justify-between">
          <div>
            <h1 className="text-lg font-semibold text-indigo-600 ">
              Add New Team
            </h1>
            <p className="text-sm text-gray-600 ">
              Please fill in the form below to add a new team
            </p>
          </div>
          <div className="flex flex-row items-center justify-between gap-3">
            <button
              type="button"
              onClick={handleCloseForm}
              className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-black rounded-md shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex items-center px-3 py-2 text-sm font-semibold text-indigo-600 border-2 border-indigo-600 rounded-md shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Save as draft
            </button>
            <button
              type="submit"
              className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Publish
            </button>
          </div>
        </div>

        <div className="grid items-center justify-between w-full grid-cols-12 gap-4">

          <div className="flex flex-col items-start justify-start w-full col-span-6">
            <label
              htmlFor="teamName"
              className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
            >
              Team Name
            </label>
            <input
              className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
              id="teamName"
              {...register("teamName", {
                required: "team name is required",
              })}
            />
            <FormFieldError errors={errors} field={"teamName"} />
          </div>

          <div className="flex flex-col items-start justify-start w-full col-span-6">
            <label
              htmlFor="teamHomeClub"
              className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
            >
              Home Club
            </label>
            <input
              id="teamHomeClub"
              className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
              {...register("teamHomeClub", {
                required: "team home club is required",
              })}
            />

            <FormFieldError errors={errors} field={"teamHomeClub"} />
          </div>
          <button
            onClick={() => setShowLocation(!showLocation)}
            type="button"
            className={`relative w-full border-b col-span-12 border-white flex justify-between items-center px-10 py-4  text-gray-700 hover:bg-gray-300 ${
              showLocation ? "bg-gray-300" : "bg-gray-200"
            }`}
          >
            <span>Team Address</span>
            <svg
              data-accordion-icon
              className={`w-3 h-3 ${showLocation ? "" : "rotate-180"} shrink-0`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path stroke="currentColor" d="M9 5 5 1 1 5" />
            </svg>
          </button>
          <div
            className={`grid items-center border border-gray-300 justify-between col-span-12 grid-cols-12 gap-4 transition-all ease-in-out  ${
              showLocation
                ? "max-h-[1000px] opacity-100 duration-500 p-5"
                : " max-h-0 opacity-0 duration-800 overflow-hidden"
            }`}
          >
            <div className="flex flex-col items-start justify-start col-span-6 sm:col-span-4">
              <label
                htmlFor="country"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Country
              </label>
              <input
                className="w-full border-2 border-gray-200 bg-white py-1.5 p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm rounded-md sm:leading-6"
                id="country"
                {...register("country")}
              />
              <FormFieldError errors={errors} field={"country"} />
            </div>
            <div className="flex flex-col items-start justify-start col-span-6 sm:col-span-4">
              <label
                htmlFor="city"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                City
              </label>
              <input
                className="w-full border-2 border-gray-200 bg-white py-1.5 p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm rounded-md sm:leading-6"
                id="city"
                {...register("city")}
              />
              <FormFieldError errors={errors} field={"city"} />
            </div>
            <div className="flex flex-col items-start justify-start col-span-6 sm:col-span-4">
              <label
                htmlFor="state"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                State
              </label>
              <input
                className="w-full border-2 border-gray-200 bg-white py-1.5 p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm rounded-md sm:leading-6"
                id="state"
                {...register("state")}
              />
              <FormFieldError errors={errors} field={"state"} />
            </div>
            <div className="flex flex-col items-start justify-start col-span-12 sm:col-span-8">
              <label
                htmlFor="address1"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Address
              </label>
              <input
                className="w-full border-2 border-gray-200 bg-white py-1.5 p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm rounded-md sm:leading-6"
                id="address1"
                {...register("address1")}
              />
              <FormFieldError errors={errors} field={"address1"} />
            </div>
            <div className="flex flex-col items-start justify-start col-span-6 sm:col-span-4">
              <label
                htmlFor="zip"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Zip
              </label>
              <input
                className="w-full border-2 border-gray-200 bg-white py-1.5 p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm rounded-md sm:leading-6"
                id="zip"
                {...register("zip")}
              />
              <FormFieldError errors={errors} field={"zip"} />
            </div>
          </div>
          <div className="flex flex-col items-start justify-start w-full col-span-12 sm:col-span-8 ">
              <div className="flex flex-row items-center justify-start px-4 py-2 bg-white border rounded-md w-full">
                  <MagnifyingGlassIcon className="w-5 h-5" />
                  <input
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setTimeout(() => setIsFocused(false), 100)}
                    placeholder="search by name, USATT-ID"
                    type="search"
                    className="px-1 w-full bg-transparent border-none outline-none focus:outline-none"
                  />
                </div>
                {isFocused && (
                  <div className="absolute z-10 mt-11 bg-white border border-gray-300 rounded shadow-lg max-h-60 overflow-y-auto">
                    {filteredPlayers?.length === 0 ? (
                      <div className="p-2">No players found</div>
                    ) : (
                      filteredPlayers.map((player) => (
                        <button
                          onMouseDown={(e) => e.preventDefault()} // Prevent blur on mousedown
                          type="button"
                          key={player.id}
                          onClick={()=>setSeclectedPlayer(player)}
                          className="flex justify-between items-center w-[220px] p-1 m-1 bg-gray-100 hover:bg-gray-200"
                        >
                          <div className="p-1">
                            <div className="text-[10px]">
                              #USATT: {player.memberId} - Rating:{" "}
                              {player.rating}
                            </div>
                            <div className="text-sm">
                              {player.firstName} {player.lastName}
                            </div>
                          </div>
                          <div className="h-4 w-4 flex justify-center items-center rounded bg-gray-100">
                            <div className="font-medium text-sm text-black">
                              +
                            </div>
                          </div>
                        </button>
                      ))
                    )}
                  </div>
                )}
              </div>
              {selectedPlayer !== "" && (
                <div className="px-3 rounded-lg col-span-8 bg-blue-600 text-white pb-3">
                  <div className="flex justify-end">
                    <button onClick={() => setSeclectedPlayer("")} className="">
                      x
                    </button>
                  </div>
                  <div className="">
                    <div className="flex text-[10px] items-center gap-2">
                      <div className=" text-gray-300">
                        Rating: <span className="text-white font-medium">{selectedPlayer?.rating}</span>
                      </div>
                      <div className=" font-medium text-gray-300">
                        MEMBER ID: <span className="bg-yellow-500 px-3 text-white font-medium">{selectedPlayer?.tempId !== 0 ? selectedPlayer?.tempId : selectedPlayer?.memberId }</span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 my-2">
                      <div className=" font-medium">
                        {selectedPlayer?.firstName}
                      </div>
                      <div className=" font-medium">
                        {selectedPlayer?.lastName}
                      </div>
                    </div>
                    <div className="flex text-[10px] items-center gap-2">
                      <div className=" text-gray-300">
                        Membership Registered:
                      </div>
                      <div className=" font-medium">
                        {selectedPlayer?.membershipStartDate}
                      </div>
                    </div>
                    <div className="flex text-[10px] items-center gap-2">
                      <div className=" text-gray-300">Membership Expiry:</div>
                      <div className=" font-medium">
                        {selectedPlayer?.membershipExpiryDate}
                      </div>
                    </div>
                  </div>
                </div>
              )}
        </div>
      </div>
    </form>
  );
};

export default AddTeamForm;
