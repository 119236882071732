import React, { useEffect } from "react";
import {
  useGetTournamentEntriesUserQuery,
} from "../../../redux/features/api/apiSlice";
import loadingImg from "../../../images/loading-image.svg";
import { useParams } from "react-router-dom";

const UserPlayersTab = ({ tournament, event, eventEntries }) => {
  let { tournamentId } = useParams();
  const { data, isLoading, refetch } = useGetTournamentEntriesUserQuery(
    tournamentId,
    { skip: !tournament }
  );

  useEffect(() => {
    if (tournament) {
      refetch();
    }
  }, [tournamentId]);

  let today = new Date();
  today.setDate(today.getDate() + 1);
  let tommorow = today.toLocaleDateString("en-CA");

  return (
    <>
      {!tournament?.customSettings?.enablePlayerList &&
      tommorow < tournament?.startDate ? (
        <div className="w-full">
          <div className="mt-20 text-center text-gray-500">
            Player will show 1 day before tournament starts
          </div>
        </div>
      ) : (
        <>
        {(isLoading) && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay">
          <div className="  relative mx-3">
            <img className="" src={loadingImg} alt="" />
          </div>
        </div>
      )}
        <div className="relative overflow-x-auto ">
          <div className="my-5 flex justify-between ">
            <div className="text-sm text-gray-500">
              {tournament ? data?.data?.length : eventEntries.length} players
              found
            </div>
          </div>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 shadow-md sm:rounded-lg">
            <thead className="text-xs text-gray-700 uppercase bg-gray-200">
              <tr>
                <th scope="col" className="px-6 py-3 w-[35%]">
                  Player
                </th>
                <th scope="col" className="px-6 py-3 w-[20%]">
                  Events
                </th>
                <th scope="col" className="px-6 py-3 w-[40%]"></th>
              </tr>
            </thead>
            {tournament && (
              <tbody>
                {data &&
                  data?.data?.map((entry, i) => (
                    <tr
                      key={i}
                      className="odd:bg-gray-50  even:bg-gray-100 border-b"
                    >
                      <th
                        scope="row"
                        className="px-1 py-1 font-medium text-gray-900 whitespace-nowrap "
                      >
                        <div className="bg-white rounded-xl px-3">
                          <div className="flex gap-5">
                            <div className="text-[10px] text-gray-500">
                              RATING: {entry?.player?.rating}
                            </div>
                            <div className="text-[10px] text-gray-500 font-medium">
                              {entry?.player?.club && (
                                <div className="text-[10px] text-gray-500 font-medium">
                                  {entry?.player?.club?.clubName},{" "}
                                  {entry?.player?.club?.region},{" "}
                                  {entry?.player?.club?.state}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="text-lg">
                            {entry?.player?.firstName},{" "}
                            {entry?.player?.lastName}
                          </div>
                        </div>
                      </th>
                      <td className="px-6 py-1">
                        {" "}
                        <div className="flex flex-wrap gap-1">
                          {entry?.events?.map((event, i) => (
                            <div
                              key={i}
                              className="text-xs bg-gray-300 rounded-sm px-3"
                            >
                              {event.eventName}
                            </div>
                          ))}
                        </div>
                      </td>
                      <td className="px-6 py-1"></td>
                    </tr>
                  ))}
              </tbody>
            )}
            {event && (
              <tbody>
                {eventEntries &&
                  eventEntries?.map((entry, i) => (
                    <tr
                      key={i}
                      className="odd:bg-gray-50  even:bg-gray-100 border-b"
                    >
                      <th
                        scope="row"
                        className="px-1 py-1 font-medium text-gray-900 whitespace-nowrap "
                      >
                        <div className="bg-white rounded-xl px-3">
                          <div className="flex gap-5">
                            <div className="text-[10px] text-gray-500">
                              RATING: {entry?.player?.rating}
                            </div>
                            <div className="text-[10px] text-gray-500 font-medium">
                              {entry?.player?.club && (
                                <div className="text-[10px] text-gray-500 font-medium">
                                  {entry?.player?.club?.clubName},{" "}
                                  {entry?.player?.club?.region},{" "}
                                  {entry?.player?.club?.state}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="text-lg">
                            {entry?.player?.firstName},{" "}
                            {entry?.player?.lastName}
                          </div>
                        </div>
                      </th>
                      <td className="px-6 py-1">
                        {" "}
                        <div className="flex flex-wrap gap-1">
                          {entry?.events?.map((event, i) => (
                            <div
                              key={i}
                              className="text-xs bg-gray-300 rounded-sm px-3"
                            >
                              {event.eventName}
                            </div>
                          ))}
                        </div>
                      </td>
                      <td className="px-6 py-1"></td>
                    </tr>
                  ))}
              </tbody>
            )}
          </table>
        </div>
        </>
      )}
    </>
  );
};

export default UserPlayersTab;
