import React, { useEffect, useState } from "react";
import MatchesTab from "../../../components/sections/tabs/MatchesTab";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { classNames } from "../../../utils/classNames";
import {
  useGetEventsByTournamentIdQuery,
  useGetTournamentByIdQuery,
  useGetTournamentEntriesQuery,
  useImportPlayerMutation,
  useLazyGetTournamentEntriesQuery,
} from "../../../redux/features/api/apiSlice";
import { useSelector } from "react-redux";
import EventsPage from "../Events/EventsPage";
import AddPlayerForm from "../Players/AddPlayerForm";
import TablesTab from "../../../components/sections/tabs/TablesTab";
import { SquaresPlusIcon } from "@heroicons/react/24/solid";
import AddTournamentForm from "./AddTournamnetForm";
import TournamentFinanceTab from "../../../components/sections/tabs/TournamentFinanceTab";
import AdminPlayersTab from "../../../components/sections/tabs/AdminPlayersTab";
import TeamsPage from "../Teams/TeamsPage";
import loading from "../../../images/loading-image.svg";
import { toast } from "react-toastify";
import ExportResult from "./ExportResult";

const handleExportMembershipPlayers = async ({
  tournamentEntries,
  tournament,
}) => {
  const headers = [
    "Membership",
    "LastName",
    "FirstName",
    "DOB",
    "Email",
    "Gender",
    "Address#1",
    "City",
    "State",
    "Zip",
    "Phone#",
    "Citizenship",
    "Representing",
    "ProductId",
    "EstRating",
  ];

  const csvContent = [
    headers.join(","), // CSV header
    ...tournamentEntries.map((entry) =>
      [
        entry.player.tempId,
        entry.player.lastName,
        entry.player.firstName,
        entry.player.dob,
        entry.player.email,
        entry.player.sex,
        entry.player.address1,
        entry.player.city,
        entry.player.state,
        entry.player.zip,
        entry.player.phone,
        entry.player.city,
        entry.player.country,
        entry.player.productId,
        entry.player.rating,
      ].join(",")
    ),
  ].join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `Memberships_${tournament.id}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default function TournamentPage() {
  let { tournamentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { isFetching: isFetchingTournament, refetch: refetchTournament } =
    useGetTournamentByIdQuery(tournamentId);
  const [showForm, setShowForm] = useState(false);
  const [playerForm, setPlayerForm] = useState(false);
  const [exportMemberships, setExportMemberships] = useState(false);
  const [exportResults, setExportResults] = useState(false);
  const [more, setMore] = useState(false);
  const { tournament } = useSelector((state) => state.tournaments);

  useGetEventsByTournamentIdQuery(tournamentId);
  const { events } = useSelector((state) => state.events);
  const [fetchEntries ] = useLazyGetTournamentEntriesQuery();
  const { tournamentEntries } = useSelector((state) => state.entries);


  useEffect(() => {
    if (tournament && tournament.id === tournamentId) {
      fetchEntries(tournamentId)
    }
  }, [tournament, tournamentId, fetchEntries]);

  const tabs = [
    {
      name: "events",
      href: "#events",
      element: (key) => <EventsPage key={key} />,
    },
    {
      name: "matches",
      href: "#matches",
      element: (key) => (
        <MatchesTab tournamentData={tournament} tournament={true} player={false} event={false} admin={true} key={key} />
      ),
    },
    // {
    //   name: "teams",
    //   href: "#teams",
    //   element: (key) => <TeamsPage key={key} />,
    // },
    {
      name: "players",
      href: "#players",
      element: (key) => (
        <AdminPlayersTab
          entries={tournamentEntries}
          key={key}
          tournamentLevel={true}
          eventLevel={false}
        />
      ),
    },
    {
      name: "tables",
      href: "#tables",
      element: (key) => <TablesTab key={key} />,
    },
    {
      name: "finances",
      href: "#finances",
      element: (key) => <TournamentFinanceTab key={key} />,
    },
  ];

  const handleActiveTabChange = (tab) => {
    setActiveTab(tab.name);
    navigate(tab.href);
  };
  const handlePlayerForm = () => {
    setPlayerForm(false);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const handleExportMemberships = async () => {
    if (exportMemberships) return;
    setExportMemberships(true);
    try {
      await handleExportMembershipPlayers({ tournamentEntries, tournament });
      toast.success("Memberships Exported");
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setExportMemberships(false);
    }
  };

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    if (!location.hash) {
      setActiveTab(tabs[0].name);
    } else {
      const currentTab = tabs.find((tab) => tab.href === location.hash);
      if (currentTab) {
        setActiveTab(currentTab.name);
      }
    }
  }, [location.hash]);

  useEffect(() => {
    if (tournamentId) {
      refetchTournament(); // Refetch tournament by ID
    }
  }, [tournamentId]);


  return (
    <>
       {isFetchingTournament && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay">
          <div className="  relative mx-3">
            <img className="" src={loading} alt="" />
          </div>
        </div>
      )}
      {playerForm ? (
        <AddPlayerForm
          handleCloseForm={handlePlayerForm}
          info={{ events, tournament }}
        />
      ) : showForm ? (
        <AddTournamentForm
          handleCloseForm={handleCloseForm}
          tournament={tournament}
        />
      ) : exportResults ? (
        <ExportResult
          handleCloseResult={setExportResults}
        />
      ) : (
        <>
          <div className="flex items-start gap-2 mt-5 mb-10">
            <button onClick={() => setShowForm(true)} className="mt-1">
              <SquaresPlusIcon height={20} width={20} />
            </button>
            <div className=" px-3">
              <div className="flex items-center gap-3">
                <div className="text-lg font-bold">
                  {tournament?.tournamentName}
                </div>
                {tournament?.status === "published" ? (
                  <div className="flex items-center font-medium uppercase text-[10px] px-2 h-5 bg-blue-600 text-white rounded-sm">
                    {tournament?.status}
                  </div>
                ) : (
                  <div className="flex items-center font-medium uppercase text-[10px] px-2 h-5 bg-gray-400 text-white rounded-sm">
                    {tournament?.status}
                  </div>
                )}
              </div>
              <div className="flex items-center gap-1 mt-2">
                <div className="text-xs font-medium text-gray-500">
                  {tournament?.startDate?.split("T")[0]} -{" "}
                </div>
                <div className="text-xs font-medium text-gray-500">
                  {" "}
                  {tournament?.endDate?.split("T")[0]}
                </div>
              </div>
              <div className="flex items-center gap-1 mt-1">
                <div className="text-xs font-medium text-gray-500">
                  {tournament?.location}
                </div>
              </div>
            </div>
          </div>

          <div className="relative pb-5 border-b border-gray-200 sm:pb-0 px-3">
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="flex mt-3 lg:absolute z-20 lg:right-0 md:top-3 lg:mt-0">
                <button
                  onClick={() => setPlayerForm(true)}
                  type="button"
                  className="inline-flex items-center border border-blue-900 px-1 sm:px-3 py-2 text-[11px] sm:text-sm font-semibold text-blue-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Add Players
                </button>
                <button
                  onClick={() => setMore(!more)}
                  className="inline-flex items-center px-1 sm:px-3 py-2 ml-3 text-[11px] sm:text-sm font-semibold text-white bg-blue-600 rounded-md shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  More Options
                </button>
                {more && (
                  <div className="absolute grid space-y-3 p-3 z-10 top-12 lg:top-10 right-0 rounded-xl w-[250px] bg-white shadow-lg border-2">
                    <button
                      disabled={exportMemberships}
                      onClick={handleExportMemberships}
                      type="button"
                      className="inline-flex text-center border items-center w-max px-1 sm:px-3 py-3 ml-3 text-[11px] sm:text-sm font-semibold text-white bg-blue-600 rounded-md shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      Export Membership
                    </button>
                    <button
                      type="button"
                      onClick={() => setExportResults(true)}
                      className="inline-flex text-center w-max border items-center px-1 sm:px-3 py-3 ml-3 text-[11px] sm:text-sm font-semibold text-white bg-orange-600 rounded-md shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                    >
                      Export Results
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-8 sm:mt-4">
              <div className="">
                <nav className="flex -mb-px space-x-4 sm:space-x-8">
                  {tabs.map((tab) => (
                    <button
                      onClick={() => handleActiveTabChange(tab)}
                      key={tab.name}
                      className={classNames(
                        tab.name === activeTab
                          ? "border-indigo-500 text-indigo-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 px-1 pb-4 text-xs sm:text-sm font-medium capitalize"
                      )}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div>
            {tabs.map((tab, index) => {
              if (tab.name === activeTab) {
                let key = tab.name + "-" + index;
                return (
                  <div className="px-3" key={index}>
                    {tab.element()}
                  </div>
                );
              }
            })}
          </div>
        </>
      )}
    </>
  );
}
