import { useEffect, useMemo, useRef, useState } from "react";
import { Listbox } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import {
  useDisplayDrawQuery,
  useGetQueryEventsByTournamentIdQuery,
  useGetQueryTournamentsQuery,
} from "../../redux/features/api/apiSlice";
import { useSelector } from "react-redux";
import DisplayScreen from "./DisplayScreen";
import loadingImg from "../../images/loading-image.svg";

const Display = () => {
  const [activeTab, setActiveTab] = useState("active");
  const [display, setDisplay] = useState(false);
  const [selected, setSelected] = useState(null);

  const [selectedEvents, setSelectedEvents] = useState([]);

  const { isFetching: tournamentFetching, refetch } =
    useGetQueryTournamentsQuery(
      new URLSearchParams({
        activeTab: activeTab,
        status: "published",
      }).toString()
    );
  const { data: events = [], isFetching: eventFetching } =
    useGetQueryEventsByTournamentIdQuery(
      selected ? { tournamentId: selected.id, query: "published" } : null,
      { skip: !selected || display }
    );

  const { queryTournaments } = useSelector((state) => state.tournaments);

  const { isFetching: drawFetching } = useDisplayDrawQuery(
    selectedEvents
      ? new URLSearchParams({ events: selectedEvents }).toString()
      : null,
    { skip: !display }
  );

  const { displayDraws } = useSelector((state) => state.draws);
  const [currentDrawIndex, setCurrentDrawIndex] = useState(0);
  const [stage, setStage] = useState(false);

  const updateStageBasedOnDraw = (index) => {
    const currentDraw = displayDraws[index];
    if (currentDraw?.drawFormat === "RR") {
      setStage(currentDraw?.roundRobinSettings?.activateSE || false);
    } else {
      setStage(true);
    }
  };

  const handleNext = () => {
    if (currentDrawIndex + 1 === displayDraws?.length) {
      const nextIndex = 0;
      setCurrentDrawIndex(nextIndex);
      updateStageBasedOnDraw(nextIndex);
    } else {
      const nextIndex = currentDrawIndex + 1;
      setCurrentDrawIndex(nextIndex);
      updateStageBasedOnDraw(nextIndex);
    }
  };

  const handlePrev = () => {
    if (currentDrawIndex === 0) {
      const nextIndex = displayDraws.length - 1;
      setCurrentDrawIndex(nextIndex);
      updateStageBasedOnDraw(nextIndex);
    } else {
      const nextIndex = currentDrawIndex - 1;
      setCurrentDrawIndex(nextIndex);
      updateStageBasedOnDraw(nextIndex);
    }
  };

  useEffect(() => {
    if (displayDraws?.length > 0) {
      updateStageBasedOnDraw(currentDrawIndex);
    }
  }, [displayDraws, currentDrawIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (displayDraws?.length > 1) {
        handleNext();
      }
    }, 10000);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [display, currentDrawIndex, displayDraws]);

  const handleSelectAll = () => {
    const allEventIds =
      events?.data
        ?.filter((event) => event?.entry === false)
        .map((event) => event.id) || [];
    setSelectedEvents(allEventIds);
  };

  const handleDeselectAll = () => {
    setSelectedEvents([]);
  };

  const handleToggleEvent = (eventId) => {
    if (selectedEvents.includes(eventId)) {
      setSelectedEvents(selectedEvents.filter((id) => id !== eventId));
    } else {
      setSelectedEvents([...selectedEvents, eventId]);
    }
  };

  const handleTabChange = () => {
    setActiveTab(activeTab === "active" ? "past" : "active");
    setSelected(null);
  };

  useEffect(() => {
    if (!tournamentFetching) {
      refetch();
    }
  }, [activeTab]);

  return (
    <>
      {(tournamentFetching || drawFetching || eventFetching) && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay">
          <div className="  relative mx-3">
            <img className="" src={loadingImg} alt="" />
          </div>
        </div>
      )}
      {!display || displayDraws?.length < 1 ? (
        <div className=" grid justify-center">
          <div className="flex justify-center mt-10 gap-3">
            <Listbox
              className="w-[350px] border rounded-lg bg-gray-100"
              value={selected}
              onChange={setSelected}
            >
              <div className="relative mt-2">
                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                  <span className="flex items-center">
                    <span className="ml-3 block truncate text-lg">
                      {selected ? selected.tournamentName : "Select Tournament"}
                    </span>
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="h-5 w-5 text-gray-400"
                    />
                  </span>
                </Listbox.Button>

                <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {queryTournaments?.length > 0 ? (
                    queryTournaments.map((tournament, i) => (
                      <Listbox.Option
                        key={i}
                        value={tournament}
                        className="group relative cursor-default border-b select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                      >
                        <div className="pl-3">
                          <span className=" block truncate font-normal group-data-[selected]:font-semibold text-lg">
                            {tournament.tournamentName}
                          </span>
                          <div className=" flex text-xs gap-1">
                            <span>{tournament.startDate}</span> -{" "}
                            <span>{tournament.endDate}</span>
                          </div>
                          <div>{tournament.location}</div>
                        </div>

                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                          <CheckIcon aria-hidden="true" className="h-5 w-5" />
                        </span>
                      </Listbox.Option>
                    ))
                  ) : (
                    <div className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-500">
                      No tournament found
                    </div>
                  )}
                </Listbox.Options>
              </div>
            </Listbox>
            <div className="flex justify-center items-center gap-2">
              <input
                type="checkbox"
                checked={activeTab !== "active"}
                className="h-5 w-5"
                onChange={handleTabChange}
              />
              <span>Show past</span>
            </div>
          </div>
          {selected && events?.data?.length > 0 ? (
            <div className="mt-5">
              <div className="text-lg font-semibold bg-gray-100 text-center p-5">
                Events for {selected.tournamentName}
              </div>
              <div className="text-white font-medium my-3 flex justify-around">
                <button
                  className="bg-blue-600 rounded p-2"
                  onClick={handleSelectAll}
                >
                  Select All
                </button>
                <button
                  className="bg-blue-600 rounded p-2"
                  onClick={handleDeselectAll}
                >
                  Deselect All
                </button>
              </div>
              <div>
                {events?.data?.map((event, i) => (
                  <div
                    className={`w-full flex mt-5 gap-3 border-b pb-3 ${
                      event?.disabled || event?.entry
                        ? "opacity-30"
                        : "opacity-100"
                    } `}
                    key={event.id}
                  >
                    <div>
                      <input
                        type="checkbox"
                        checked={selectedEvents.includes(event.id)}
                        onChange={() => handleToggleEvent(event.id)}
                        disabled={event.disabled || event?.entry}
                      />
                    </div>
                    <div>
                      <div className="text-lg font-semibold">
                        {i + 1}. {event.eventName}
                      </div>
                      <div className="text-sm text-gray-600">
                        {event.startDate.split("T")[0]} . {event.startTime}
                      </div>
                      <div className="text-sm text-gray-800">
                        Max Rating:
                        <span className="font-bold ml-1">
                          {event.entryRestrictions.maxRating}
                        </span>
                      </div>
                      <div className="text-sm text-gray-800">
                        Entry Fee:
                        <span className="font-bold ml-1">
                          {" "}
                          ${event.eventFee}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <button
                onClick={() => setDisplay(!display)}
                disabled={selectedEvents?.length === 0}
                className={`text-center ${
                  selectedEvents?.length === 0 ? "opacity-50" : ""
                } font-medium py-2 bg-blue-800 hover:bg-blue-900 text-white w-full mt-3 rounded border shadow`}
              >
                Enter Display Mode
              </button>
            </div>
          ) : (
            <div className="mt-3 bg-gray-100 border shadow rounded-xl p-5">
              <div className="text-center text-gray-500 ">No Event Found</div>
            </div>
          )}
        </div>
      ) : (
        <div>
          {displayDraws && displayDraws?.length > 0 && (
            <div className="w-full p-3">
              <div className="flex gap-3 justify-end w-full">
                {displayDraws.length > 1 && (
                  <button
                    onClick={handlePrev}
                    className="px-2 py-2 bg-gray-300 rounded text-gray-800"
                  >
                    <ChevronLeftIcon height={20} width={20} />
                  </button>
                )}
                {displayDraws.length > 1 && (
                  <button
                    onClick={handleNext}
                    className="px-2 py-2 bg-gray-300 rounded text-gray-800"
                  >
                    <ChevronRightIcon height={20} width={20} />
                  </button>
                )}
              </div>
              <DisplayScreen
                draw={displayDraws[currentDrawIndex]}
                active={stage}
                handleStage={setStage}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Display;
