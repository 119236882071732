import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FormFieldError from "../../../components/commons/formFeildError..jsx";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { playerInitialState } from "../../../redux/features/playerSlice.js";
import {
  useAddNewPlayerMutation,
  useSearchPlayerQuery,
  useUpdatePlayerMutation,
} from "../../../redux/features/api/apiSlice.js";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const AddPlayerForm = ({ handleCloseForm, info }) => {
  let { tournamentId } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const { data, refetch } = useSearchPlayerQuery(
    { searchTerm, tournamentId },
    {
      skip: !isFocused || searchTerm === "",
    }
  );
  const [addNewPlayer] = useAddNewPlayerMutation();
  const [updatePlayer] = useUpdatePlayerMutation();
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [eventFee, setEventFee] = useState(0);
  const [total, setTotal] = useState(
    parseFloat(info?.tournament?.tournamentFee) + 
    (info?.tournament?.membershipSystem === 'usa-tt' ? 25 : 0)
  );
  const [memberShip, setMemberShip] = useState(info?.tournament?.membershipSystem === 'usa-tt' ? 25 : 0);
  const [payment, setPayment] = useState(parseFloat(0));
  const [events, setEvents] = useState(info.queryEvents);
  const [selectedPlayer, setSelectedPlayer] = useState("");
  const [charges, setCharges] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [payments, setPayments] = useState([]);
  let currentDate = new Date();
  const parseDate = (dateString) => {
    const [month, day, year] = dateString.split("/");
    return new Date(`${year}-${month}-${day}`); // Convert to YYYY-MM-DD format
  };

  let membershipExpiryDate = selectedPlayer?.membershipExpiryDate
    ? parseDate(selectedPlayer?.membershipExpiryDate)
    : null;

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: playerInitialState,
  });

  const onSubmit = async (data) => {
    if (loading) return;
    setLoading(true);

    try {
      if (selectedPlayer !== "") {
        const updatePayload = {
          data: getValues(),
          tournamentId,
          pinVerification: true,
          selectedEvent,
          playerId: selectedPlayer.id,
          payment: {
            total,
            tournamentFee: info.tournament.tournamentFee,
            memberShip,
          },
        };

        const res = await updatePlayer({
          data: updatePayload,
          id: selectedPlayer.id,
        });

        if (res?.data?.error === false) {
          toast.success(res.data.data);
          handleCloseForm(); // Close form only if update was successful
        } else {
          // Handle error properly, but do not clear state
          toast.error(res.error.data.error || "Failed to update player");
        }
      } else {
        const res = await addNewPlayer({
          data,
          tournamentId,
          selectedEvent,
          payment: {
            total,
            tournamentFee: info.tournament.tournamentFee,
            memberShip,
            eventFee,
            
          },
        });

        if (res?.data?.error === false) {
          toast.success(res.data.data);
          handleCloseForm(); // Close form only if add was successful
        } else {
          toast.error(res.error.data.error || "Failed to add player");
        }
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false); // Ensure loading state is always updated
    }
  };

  const handleEvents = (event) => {
    setSelectedEvent((prevSelectedEvent) => {
      const isSelected = prevSelectedEvent.some((e) => e.id === event.id);
      let tempTotal = total;
      let tempEventFee = eventFee;

      if (isSelected) {
        tempEventFee -= parseFloat(event.eventFee || 0);
        tempTotal -= parseFloat(event.eventFee || 0);
        setTotal(tempTotal);
        setEventFee(tempEventFee);
        return prevSelectedEvent.filter((e) => e.id !== event.id);
      } else {
        tempEventFee += parseFloat(event.eventFee || 0);
        tempTotal += parseFloat(event.eventFee || 0);
        setTotal(tempTotal);
        setEventFee(tempEventFee);
        return [...prevSelectedEvent, event];
      }
    });
  };

  const handleMembershipChange = (e) => {
    const selectedFee = parseFloat(e.target.value);
    let tempTotal = total - memberShip + selectedFee;
    setTotal(tempTotal);
    setMemberShip(selectedFee);
  };

  useEffect(() => {
    if (isFocused && searchTerm) {
      const debounced = setTimeout(() => {
        refetch();
      }, 300);

      return () => clearTimeout(debounced);
    }
  }, [searchTerm, isFocused, refetch]);

  useEffect(() => {
    if (data) {
      setFilteredPlayers(data.data);
    } else {
      setFilteredPlayers([]);
    }
  }, [data]);

  const handlePlayerSelection = (player) => {
    setValue("firstName", player.firstName);
    setValue("lastName", player.lastName);
    const dob = player?.dob;
    let formattedDob = "";

    if (dob.includes("/")) {
      const [month, day, year] = dob.split("/");
      if (month && day && year) {
        const formattedMonth = month.length === 1 ? `0${month}` : month;
        const formattedDay = day.length === 1 ? `0${day}` : day;
        formattedDob = `${year}-${formattedMonth}-${formattedDay}`;
      }
    } else {
      formattedDob = dob;
    }

    setValue("dob", formattedDob);
    setValue("sex", player.sex);
    setValue("homeClub", player.homeClub);
    setValue("phone", player.phone);
    setValue("rating", player.rating);
    setValue("email", player.email);
    setValue("pin", player.pin);
    setSearchTerm(""); // Clear the search term
    setSelectedPlayer(player); // Keep player selected

    const selectedEvents = player?.entries?.[0]?.events || [];
    setSelectedEvent(selectedEvents);

    const totalEventFees = selectedEvents.reduce(
      (sum, e) => sum + parseFloat(e.eventFee || 0),
      0
    );
    // const currentDate = new Date();
    // if(player.membershipExpiryDate < currentDate){
    //   setMemberShip(25)
    // }
    const temp = total + totalEventFees
    setTotal(temp);
    setIsFocused(false); // Close the dropdown
  };

  const clearPlayerSelection = () => {
    setSelectedPlayer("");
    setMemberShip(0);
    setDiscounts([]);
    setCharges([]);
    setPayments([]);
    setPayment(0);
    reset();
  };

  useEffect(() => {
    const subscription = watch((value) => {
      const updatedEvents = info.queryEvents.map((event) => {
        const disabled =
          (value.rating !== 0 &&
            (value.rating < event.entryRestrictions.minRating ||
              value.rating > event.entryRestrictions.maxRating)) ||
          event.entryRestrictions.maxNoOfPlayers <= event.players.length ||
          value.sex !== event.sex ||
          (event.entryRestrictions.minDOB !== "" &&
            event.entryRestrictions.maxDOB !== "" &&
            (new Date(value.dob) < new Date(event.entryRestrictions.maxDOB) ||
              new Date(value.dob) > new Date(event.entryRestrictions.minDOB)));

        return { ...event, disabled };
      });

      setEvents(updatedEvents);
      const validSelectedEvents = selectedEvent.filter(
        (selected) =>
          !updatedEvents.find(
            (event) => event.id === selected.id && event.disabled
          )
      );

      const newEventFee = validSelectedEvents.reduce(
        (acc, event) => acc + parseFloat(event.eventFee),
        0
      );
      const newTotal =
        parseFloat(info.tournament.tournamentFee) + newEventFee + memberShip;

      setSelectedEvent(validSelectedEvents);
      setEventFee(newEventFee);
      setTotal(newTotal);
    });

    return () => subscription.unsubscribe();
  }, [watch, info.queryEvents, selectedEvent, memberShip]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-3 sm:p-8 space-y-4">
          <div className="md:flex items-center justify-between">
            <div className="text-center">
              <h1 className="text-lg font-semibold text-blue-600 ">
                {selectedPlayer === ""
                  ? "Add New Player"
                  : "Adding Existing Player"}
              </h1>
              <p className="text-sm text-gray-600 ">
                Please fill in the form below to add a new player
              </p>
            </div>
            <div className="flex flex-row items-center justify-between gap-3 mt-5 md:mt-0">
              <button
                type="button"
                onClick={handleCloseForm}
                className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-gray-500 rounded-md shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-blue-600 rounded-md shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {loading ? "loading.." : "Submit"}
              </button>
            </div>
          </div>
          <div className="border-2 border-gray-200 rounded-2xl p-1 sm:p-3 grid grid-cols-9">
            <div className="grid items-center justify-between col-span-9 md:col-span-4 grid-cols-12 gap-4 sm:pr-3 pb-10">
              <div className="col-span-12 font-semibold text-gray-400">
                Player Info
              </div>
              {!info?.entry?.player?.id && (
                <div className="flex flex-col items-start justify-start w-full col-span-12 sm:col-span-10 ">
                  <div className="flex flex-row items-center justify-start px-4 py-2 bg-white border rounded-md w-full">
                    <MagnifyingGlassIcon className="w-5 h-5" />
                    <input
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setTimeout(() => setIsFocused(false), 100)} // Add delay before losing focus
                      placeholder="Search by name, USATT-ID"
                      type="search"
                      className="px-1 w-full bg-transparent border-none outline-none focus:outline-none"
                    />
                  </div>

                  {isFocused && (
                    <div className="absolute z-10 mt-11 bg-white border border-gray-300 rounded shadow-lg max-h-60 overflow-y-auto">
                      {filteredPlayers?.length === 0 ? (
                        <div className="p-2">No players found</div>
                      ) : (
                        filteredPlayers.map((player) => (
                          <button
                            onMouseDown={(e) => e.preventDefault()} // Prevent blur on mousedown
                            onClick={() => {
                              handlePlayerSelection(player);
                              const selectedEvents =
                                player?.entries?.[0]?.events || [];
                              setSelectedEvent(selectedEvents);
                              const totalEventFees = selectedEvents.reduce(
                                (sum, e) => sum + parseFloat(e.eventFee || 0),
                                0
                              );
                              setTotal(
                                parseFloat(
                                  info?.tournament?.tournamentFee || 0
                                ) +
                                  totalEventFees +
                                  parseInt(
                                    player?.entries[0]?.membershipFee || 25
                                  ) +
                                  (player?.entries?.[0]?.charges?.reduce(
                                    (sum, c) => sum + parseFloat(c.charge),
                                    0
                                  ) || 0)
                              );
                              setDiscounts(player?.entries[0]?.discounts);
                              setCharges(player?.entries[0]?.charges);
                              setPayments(player?.entries[0]?.payments);
                              setMemberShip(
                                parseInt(player?.entries[0]?.membershipFee || 25)
                              );
                              setPayment(
                                (player?.entries?.[0]?.payments?.reduce(
                                  (sum, p) => sum + parseFloat(p.payment),
                                  0
                                ) || 0) +
                                  (player?.entries?.[0]?.discounts?.reduce(
                                    (sum, d) => sum + parseFloat(d.discount),
                                    0
                                  ) || 0)
                              );
                            }}
                            key={player.id}
                            type="button"
                            className="flex justify-between items-center w-[220px] p-1 m-1 bg-gray-100 hover:bg-gray-200"
                          >
                            <div className="p-1">
                              <div className="text-[10px]">
                                #USATT: {player.memberId} - Rating:{" "}
                                {player.rating}
                              </div>
                              <div className="text-sm">
                                {player.firstName} {player.lastName}
                              </div>
                            </div>
                            <div className="h-4 w-4 flex justify-center items-center rounded bg-gray-100">
                              <div className="font-medium text-sm text-black">
                                +
                              </div>
                            </div>
                          </button>
                        ))
                      )}
                    </div>
                  )}
                </div>
              )}
              {selectedPlayer !== "" ? (
                <>
                  <div className="px-3 rounded-lg col-span-8 bg-blue-600 text-white pb-3">
                    <div className="flex justify-end mt-2">
                      <button
                        onClick={clearPlayerSelection}
                        className="h-5 w-5 flex justify-center items-center rounded-sm bg-gray-400 hover:bg-gray-300"
                      >
                        x
                      </button>
                    </div>
                    <div className="">
                      <div className="flex text-xs items-center gap-2">
                        <div className=" text-gray-300">
                          Rating:{" "}
                          <span className="text-white font-medium">
                            {selectedPlayer?.rating}
                          </span>
                        </div>
                        <div className=" font-medium text-gray-300">
                          MEMBER ID:{" "}
                          <span
                            className={`${
                              selectedPlayer?.memberId
                                ? "bg-white text-blue-800"
                                : "bg-yellow-500 text-white"
                            }  px-3  font-medium`}
                          >
                            {selectedPlayer?.memberId
                              ? selectedPlayer?.memberId
                              : selectedPlayer?.tempId}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center text-lg gap-2 my-2">
                        <div className=" font-medium">
                          {selectedPlayer?.firstName},
                        </div>
                        <div className=" font-medium">
                          {selectedPlayer?.lastName}
                        </div>
                      </div>
                      {membershipExpiryDate && (
                        <>
                          <div className="flex text-xs items-center gap-2">
                            <div className="text-gray-300">
                              Membership Expiry:
                            </div>
                            {currentDate > membershipExpiryDate ? (
                              <div className="font-medium bg-red-600 px-2">
                                {selectedPlayer?.membershipExpiryDate}
                              </div>
                            ) : (
                              <div className="font-medium">
                                {selectedPlayer?.membershipExpiryDate}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {selectedPlayer?.userId !== null ? (
                    <div className="flex flex-col items-start justify-start w-full col-span-6">
                      <label
                        htmlFor="pin"
                        className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                      >
                        PIN
                      </label>
                      <input
                        className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                        id="pin"
                        {...register("pin", {
                          required: "PIN is required",
                        })}
                      />
                      <FormFieldError errors={errors} field={"pin"} />
                    </div>
                  ) : (
                    <div className="flex flex-col items-start justify-start w-full col-span-12 ">
                      <div className="text-xs text-red-600 text-center">
                        Player has not Registered his account and cannot be
                        Added!
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="flex flex-col items-start justify-start w-full col-span-6">
                    <label
                      htmlFor="firstName"
                      className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                    >
                      First Name
                    </label>
                    <input
                      className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                      id="firstName"
                      {...register("firstName", {
                        required: "First Name is required",
                      })}
                    />
                    <FormFieldError errors={errors} field={"firstName"} />
                  </div>

                  <div className="flex flex-col items-start justify-start w-full col-span-6">
                    <label
                      htmlFor="lastName"
                      className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                    >
                      Last Name
                    </label>
                    <input
                      id="lastName"
                      className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                      {...register("lastName", {
                        required: "Last Name is required",
                      })}
                    />

                    <FormFieldError errors={errors} field={"lastName"} />
                  </div>

                  <div className="flex flex-col items-start justify-start w-full col-span-6">
                    <label
                      htmlFor="sex"
                      className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                    >
                      Gender
                    </label>
                    <select
                      className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                      id="sex"
                      {...register("sex", { required: "Gender is required" })}
                    >
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </select>
                    <FormFieldError errors={errors} field={"sex"} />
                  </div>
                  <div className="flex flex-col items-start justify-start w-full col-span-6">
                    <label
                      htmlFor="dob"
                      className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                    >
                      DOB
                    </label>
                    <input
                      className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                      id="dob"
                      type="date"
                      max={new Date().toISOString().split("T")[0]}
                      {...register("dob", {
                        required: " D.O.B is required",
                      })}
                    />
                    <FormFieldError
                      errors={errors?.entryRestrictions}
                      field={"dob"}
                    />
                  </div>
                  <div className="flex flex-col items-start justify-start w-full col-span-6">
                    <label
                      htmlFor="homeClub"
                      className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                    >
                      Home Club
                    </label>
                    <input
                      className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                      id="homeClub"
                      type="text"
                      {...register("homeClub")}
                    />
                    <FormFieldError errors={errors} field={"homeClub"} />
                  </div>

                  <div className="flex flex-col items-start justify-start w-full col-span-6">
                    <label
                      htmlFor="phone"
                      className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                    >
                      Phone
                    </label>
                    <input
                      className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                      id="phone"
                      type="tel"
                      {...register("phone")}
                    />
                    <FormFieldError errors={errors} field={"phone"} />
                  </div>

                  <div className="flex flex-col items-start justify-start w-full col-span-6">
                    <label
                      htmlFor="email"
                      className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                    >
                      Email
                    </label>
                    <input
                      disabled={info?.entry?.player?.id}
                      className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                      id="email"
                      type="email"
                      {...register("email", {
                        required: "Email is required",
                      })}
                    />
                    <FormFieldError errors={errors} field={"email"} />
                  </div>
                  <div className="flex flex-col items-start justify-start w-full col-span-6">
                    <label
                      htmlFor="rating"
                      className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                    >
                      Rating
                    </label>
                    <input
                      className="w-full col-span-8 bg-gray-200  border-2  py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                      id="rating"
                      disabled
                      type="number"
                      {...register("rating")}
                    />
                    <FormFieldError errors={errors} field={"rating"} />
                  </div>
                </>
              )}
            </div>

            <div className="col-span-9 md:col-span-2 px-3 md:border-x-2">
              <div className="col-span-12 font-semibold text-gray-400">
                Select Event
              </div>

              {events?.map((event, i) => {
                const isSelected = selectedEvent.some(
                  (selected) => selected.id === event.id
                );

                return (
                  <div
                    className={`w-full flex mt-5 gap-3 border-b pb-3 ${
                      event.disabled || !event.entry
                        ? "opacity-30"
                        : "opacity-100"
                    } `}
                    key={event.id}
                  >
                    <div>
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => handleEvents(event)}
                        disabled={
                          event.disabled ||
                          !event.entry ||
                          (selectedPlayer?.entries &&
                            selectedPlayer?.entries[0]?.events?.some(
                              (e) => e?.id === event.id
                            ))
                        }
                      />
                    </div>
                    <div>
                      <div className="text-lg font-medium">
                        {i + 1}. {event.eventName}
                      </div>
                      <div className="text-xs text-gray-600">
                        {event.startDate.split("T")[0]} . {event.startTime}
                      </div>
                      <div className="text-xs text-gray-800">
                        Rating: {event.entryRestrictions.minRating}-
                        {event.entryRestrictions.maxRating}
                      </div>
                      <div className="text-xs text-gray-800">
                        Entry Fee: ${event.eventFee}
                      </div>
                      {(event.disabled || !event.entry) && (
                        <div className="px-2 bg-gray-400 text-xs text-center mt-1">
                          ineligible
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="mt-10 sm:mt-0 col-span-9 md:col-span-3 pl-3">
              <div className="font-semibold text-gray-400">Payment Details</div>
              <div className="mt-5 text-sm">
                <div className="flex justify-between">
                  <div className="text-gray-500">Tournament Fee:</div>
                  <div className="font-medium">
                    ${info.tournament.tournamentFee}
                  </div>
                </div>
                <div>
                  {selectedEvent?.map((event, i) => (
                    <div key={i} className="flex justify-between mt-1">
                      <div className="text-gray-500">{event.eventName}</div>
                      <div className="font-medium">${event.eventFee}</div>
                    </div>
                  ))}
                </div>
                {info?.tournament?.membershipSystem === "usa-tt" ? (
                  <div className="flex flex-col items-start justify-start w-full mt-3">
                    <label
                      htmlFor="memberShip"
                      className="block col-span-4 text-[10px] font-medium leading-6 text-gray-500"
                    >
                      USATT MEMBERSHIP FEE
                    </label>
                    <select
                      className="w-full col-span-8 border-2 bg-transparent py-1.5 p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm rounded-sm sm:leading-6"
                      id="memberShip"
                      value={memberShip}
                      // disabled={selectedPlayer !== ""}
                      onChange={handleMembershipChange}
                    >
                      <option value="0">None</option>
                      <option value="50">
                        USATT Adult Tournament Pass $50
                      </option>
                      <option value="20">
                        USATT Junior Tournament Pass $20
                      </option>
                      <option value="25">USATT Adult Basic Plan $25</option>
                      <option value="75">USATT Pro Plan $75</option>
                      <option value="1300">USATT Lifetime Plan $1300</option>
                    </select>
                  </div>
                ) : (
                  <div className="flex flex-col items-start justify-start w-full mt-3">
                    <label
                      htmlFor="memberShip"
                      className="block col-span-4 text-[10px] font-medium leading-6 text-gray-500"
                    >
                      MEMBERSHIP
                    </label>
                    <select
                      className="w-full col-span-8 border-2 bg-transparent py-1.5 p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm rounded-sm sm:leading-6"
                      id="memberShip"
                      value={memberShip}
                      onChange={handleMembershipChange}
                    >
                      <option value="0">None</option>
                    </select>
                  </div>
                )}
                <div className="mt-2 space-y-1 opacity-50">
                  {discounts?.map((discount, i) => (
                    <div
                      key={i}
                      className="flex justify-between items-center px-3 border rounded text-sm py-1 bg-green-600"
                    >
                      <div className="flex gap-2">
                        <div className="text-gray-100">Discount -</div>
                        <div className="text-gray-100">
                          {discount.discountNote}:
                        </div>
                        <div className="text-white font-medium">
                          {" "}
                          ${discount.discount}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-2 space-y-1 opacity-50">
                  {charges?.map((charge, i) => (
                    <div
                      key={i}
                      className="flex justify-between items-center px-3 border rounded text-sm py-1 bg-red-700"
                    >
                      <div className="flex gap-3">
                        <div className="text-gray-100">Charge -</div>
                        <div className="text-gray-100">
                          {charge.chargeNote}:
                        </div>
                        <div className="text-white font-medium">
                          {" "}
                          ${charge.charge}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-2 space-y-1 opacity-50">
                  {payments?.map((payment, i) => (
                    <div
                      key={i}
                      className="flex justify-between items-center px-3 border rounded text-sm py-1 bg-blue-700"
                    >
                      <div className="flex gap-3">
                        <div className="text-gray-100">Payment -</div>
                        <div className="text-gray-100">
                          {payment.paymentNote}:
                        </div>
                        <div className="text-white font-medium">
                          {" "}
                          ${payment.payment}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex justify-between mt-10 border-t pt-3">
                  <div className="font-medium">Total:</div>
                  <div className="font-medium">${total}</div>
                </div>

                <div className="flex justify-between mt-2">
                  <div className="font-light">Payment:</div>
                  <div className="font-medium">${payment}</div>
                </div>

                <div className="flex justify-between mt-2">
                  <div className="font-light">Remaining:</div>
                  <div className="font-medium">${total - payment}</div>
                </div>

                <div className="h-[1px] bg-gray-400 w-full my-5"></div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddPlayerForm;
