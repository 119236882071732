import React, { useEffect, useState } from "react";
import AddEventForm from "./AddEventForm";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetEventsByTournamentIdQuery } from "../../../redux/features/api/apiSlice";
import { Squares2X2Icon, Bars2Icon } from "@heroicons/react/24/solid";
import { EventCard } from "../../../components";
import loading from "../../../images/loading-image.svg";

const EventsPage = () => {
  let { tournamentId } = useParams();
  const [showForm, setShowForm] = useState(false);
  const [event, setEvent] = useState(false);
  const { isFetching, refetch } = useGetEventsByTournamentIdQuery(tournamentId);
  const { events } = useSelector((state) => state.events);
  const [activeTab, setActiveTab] = useState("active");
  const [dataView, setDataView] = useState("grid"); //! grid | tabel

  const handleCloseForm = () => {
    setShowForm(false);
    setEvent("");
  };

  useEffect(() => {
    if (!isFetching) {
      refetch();
    }
  }, [tournamentId]);

  return (
    <div>
      {showForm ? (
        <AddEventForm handleCloseForm={handleCloseForm} event={event} />
      ) : (
        <div className="min-h-screen ">
          <div className="flex flex-row items-center justify-between w-full gap-2 py-4">
            <div className="flex flex-row items-center justify-end">
              <button
                onClick={() => setShowForm(true)}
                className="inline-flex items-center px-2 sm:px-3 py-2 border hover:shadow bg-blue-700 text-xs sm:text-sm font-semibold text-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-blue-800"
              >
                Add New Event
              </button>
            </div>
            <div className="hidden  sm:block">
              <button
                onClick={() =>
                  setDataView(dataView === "grid" ? "tabel" : "grid")
                }
                className={`px-4 py-2  uppercase rounded-s-md ${
                  dataView === "grid"
                    ? "bg-blue-900 text-white font-medium"
                    : "bg-white text-black"
                }`}
              >
                <Squares2X2Icon className="w-5 h-5" />
              </button>
              <button
                onClick={() =>
                  setDataView(dataView === "tabel" ? "grid" : "tabel")
                }
                className={`px-4 py-2  uppercase rounded-e-md ${
                  dataView === "tabel"
                    ? "bg-blue-900 text-white font-medium"
                    : "bg-white text-black"
                }`}
              >
                <Bars2Icon className="w-5 h-5 " />
              </button>
            </div>
          </div>
          {isFetching ? (
            <div className=" h-[500px] w-full flex justify-center items-center">
              <img className="-mt-32" src={loading} alt="" />
            </div>
          ) : events?.length < 1 ? (
            <div className="text-center mt-20 font-medium text-gray-500">
              No Events Available
            </div>
          ) : (
            <div
              className={`grid gap-4 ${
                activeTab === "grid" ? "grid-cols-4" : "grid-cols-12"
              }`}
            >
              {events &&
                events?.map((event, index) => {
                  return (
                    <div
                      key={index}
                      className={`p-6 text-white bg-gradient-to-r from-cyan-900  to-blue-800 border border-gray-300  rounded-md shadow ${
                        dataView === "grid"
                          ? "col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3"
                          : "col-span-12"
                      }`}
                    >
                      <EventCard event={event} view={dataView} />
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EventsPage;
