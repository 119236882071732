import React from "react";

const MatchCard = ({ match }) => {

  const getAlphabetFromOrder = (order) => {
    if (order === undefined || order === null) return "0";
    const alphabets = ["A", "B", "C", "D", "E", "F", "G", "H"];

    return match?.group?.type === "RR" ? alphabets[order] || String.fromCharCode(65 + order) : order + 1; // Fallback for higher values
  };
  return (
    <>
      <div className="flex">
        <div className="w-full grid grid-cols-2">
          {(() => {
            const sortedPlayers = match?.matchPlayers
              ? match.matchPlayers
                  .slice()
                  .sort(
                    (a, b) => a?.PlayerMatch?.number - b?.PlayerMatch?.number
                  )
              : [];

            let filledPlayers = [];

            if (sortedPlayers.length === 0) {
              filledPlayers = [
                {
                  PlayerMatch: { number: 0 },
                  firstName: "TBD",
                  lastName: "",
                  rating: "",
                  homeAddress: "",
                },
                {
                  PlayerMatch: { number: 1 },
                  firstName: "TBD",
                  lastName: "",
                  rating: "",
                  homeAddress: "",
                },
              ];
            } else if (sortedPlayers.length === 1) {
              // If there is one player, place them in the correct position and show TBD in the other
              const player = sortedPlayers[0];
              if (player.PlayerMatch.number === 0) {
                filledPlayers = [
                  player, // Player A
                  {
                    PlayerMatch: { number: 1 },
                    firstName: "TBD",
                    lastName: "",
                    rating: "",
                    homeAddress: "",
                  },
                ];
              } else {
                filledPlayers = [
                  {
                    PlayerMatch: { number: 0 },
                    firstName: "TBD",
                    lastName: "",
                    rating: "",
                    homeAddress: "",
                  },
                  player, // Player B
                ];
              }
            } else if (sortedPlayers.length === 2) {
              filledPlayers = sortedPlayers;
            }

            return filledPlayers?.map((player, i) => (
              <div
                key={i}
                className="px-1 py-3 flex gap-1 sm:gap-3 items-center border-l"
              >
                <div
                  className={`w-6 h-6 flex justify-center items-center text-white font-medium ${
                    player?.PlayerMatch?.number === 0
                      ? "bg-blue-600"
                      : "bg-rose-600"
                  }`}
                >
                  {(() => {
                    const order = player?.PlayerMatch?.order;
                    return getAlphabetFromOrder(order);
                  })()}
                </div>
                <div>
                  <div className="text-[10px] flex gap-3">
                    <span className="font-bold">{player?.rating || ""}</span>{" "}
                    {player?.club?.location || player?.homeAddress}
                  </div>
                  <div className="font-bold sm:font-medium text-xs sm:text-sm">
                    {player?.lastName
                      ? `${player.lastName}${
                          player.firstName ? ", " + player.firstName : ""
                        }`
                      : "TBD"}
                  </div>
                </div>
              </div>
            ));
          })()}
        </div>
      </div>
      <div className="grid w-full grid-cols-7 text-lg">
        <div className="flex flex-col items-center justify-start col-span-1 h-full">
          <div className="w-full py-1 sm:py-2 text-center text-white bg-blue-600 border border-blue-600 h-full">
            {(() => {
              const order = match?.matchPlayers
                ?.slice()
                ?.sort(
                  (a, b) => a?.PlayerMatch?.number - b?.PlayerMatch?.number
                )[0]?.PlayerMatch?.order;
              return getAlphabetFromOrder(order);
            })()}
          </div>
          <div className="w-full py-1 sm:py-2 text-center text-white border border-rose-600 bg-rose-600 h-full">
          {(() => {
              const order = match?.matchPlayers
                ?.slice()
                ?.sort(
                  (a, b) => a?.PlayerMatch?.number - b?.PlayerMatch?.number
                )[1]?.PlayerMatch?.order;
              return getAlphabetFromOrder(order);
            })()}
          </div>
        </div>

        {Array(5)
          .fill(null)
          .map((_, index) => {
            const round = match?.rounds?.[index] || {};
            return (
              <div
                className="flex flex-col items-center justify-start col-span-1 h-full"
                key={index}
              >
                <div className="w-full py-1 sm:py-2 text-center border h-full">
                  {round.A !== undefined ? round.A : ""}{" "}
                </div>
                <div className="w-full py-1 sm:py-2 text-center border h-full">
                  {round.B !== undefined ? round.B : ""}{" "}
                </div>
              </div>
            );
          })}

        <div className="flex flex-col items-center justify-start col-span-1 h-full text-white font-bold">
          <div className="w-full py-1 sm:py-2 text-center border h-full bg-blue-600">
            {match?.result?.A || 0}
          </div>
          <div className="w-full py-1 sm:py-2 text-center border h-full bg-rose-600">
            {match?.result?.B || 0}
          </div>
        </div>
      </div>
    </>
  );
};

export default MatchCard;
