import { createSlice } from "@reduxjs/toolkit";

export const teamInitialState = {
  teamName: "",
  teamHomeClub: "",
  teamCity: "",
  teamState: "",
  teamCountry: "",
  teamCaptian: "",
  teamPlayers: [],
  teamEvents: [],
};

export const teamSlice = createSlice({
  name: "team",
  initialState: teamInitialState,
  reducers: {
    increment: (state) => {},
  },
});

// Action creators are generated for each case reducer function
export const { increment } = teamSlice.actions;

export default teamSlice.reducer;
