import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { useForm } from "react-hook-form";
import FormFieldError from "../../../components/commons/formFeildError..jsx";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { playerInitialState } from "../../../redux/features/playerSlice.js";
import {
  useAddNewPlayerMutation,
  useLazyGetAllClubsQuery,
  useSearchPlayerQuery,
  useUpdatePlayerMutation,
} from "../../../redux/features/api/apiSlice.js";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const AddPlayerForm = ({ handleCloseForm, info }) => {
  const { tournamentId } = useParams();

  const [triggerFetchClubs, { data: fetchedClubsData, isLoading }] =
    useLazyGetAllClubsQuery();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchClub, setSearchClub] = useState(false);
  const [club, setClub] = useState(info?.entry?.player?.club || "");
  const [fetchedClubs, setFetchedClubs] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState("");

  const { data } = useSearchPlayerQuery(
    { searchTerm, tournamentId },
    {
      skip: !isFocused || searchTerm === "",
    }
  );
  const [addNewPlayer] = useAddNewPlayerMutation();
  const [updatePlayer] = useUpdatePlayerMutation();
  const [selectedEvent, setSelectedEvent] = useState(info?.entry?.events || []);
  const [total, setTotal] = useState(() => {
    const tournamentFee = parseFloat(info?.tournament?.tournamentFee) || 0;
    const eventFees = (info?.entry?.events || []).reduce(
      (sum, event) => sum + parseFloat(event.eventFee || 0),
      0
    );
    const membershipFee =
      info?.entry?.membershipFee !== undefined
        ? parseFloat(info?.entry?.membershipFee)
        : info?.tournament?.membershipSystem === "usa-tt"
        ? 25
        : 0;
    const otherCharges = (info?.entry?.charges || []).reduce(
      (sum, charge) => sum + parseFloat(charge.charge || 0),
      0
    );

    return tournamentFee + eventFees + membershipFee + otherCharges;
  });

  const [memberShip, setMemberShip] = useState(() =>
    info?.entry?.membershipFee !== undefined
      ? parseFloat(info?.entry?.membershipFee)
      : 25
  );
  const [charges, setCharges] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [payments, setPayments] = useState([]);
  const [selectedPlayer, setSeclectedPlayer] = useState("");
  const [openCharge, setChargeOpen] = useState(false);
  const [openDiscount, setDiscountOpen] = useState(false);
  const [openPayment, setPaymentOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  let currentDate = new Date();
  const parseDate = (dateString) => {
    const [month, day, year] = dateString.split("/");
    return new Date(`${year}-${month}-${day}`); // Convert to YYYY-MM-DD format
  };

  let membershipExpiryDate = selectedPlayer?.membershipExpiryDate
    ? parseDate(selectedPlayer?.membershipExpiryDate)
    : null;

  const [charge, setCharge] = useState({
    charge: 0.0,
    chargeNote: "",
  });
  const postCharge = (e) => {
    const { name, value } = e.target;
    setCharge({ ...charge, [name]: value });
  };

  const [discount, setDiscount] = useState({
    discount: 0.0,
    discountNote: "",
  });
  const postDiscount = (e) => {
    const { name, value } = e.target;
    setDiscount({ ...discount, [name]: value });
  };

  const [payment, setPayment] = useState({
    payment: 0.0,
    paymentNote: "",
  });
  const postPayment = (e) => {
    const { name, value } = e.target;
    setPayment({ ...payment, [name]: value });
  };

  const handleCharge = () => {
    if (charge.charge <= 0) {
      setError("Charge must be greater than 0!");
      setChargeOpen(false);
      return;
    }
    setCharges((prevCharges) => [...prevCharges, charge]);
    setTotal((prevTotal) => prevTotal + parseFloat(charge.charge));
    setCharge({ charge: 0.0, chargeNote: "" });
    setChargeOpen(false);
  };
  const removeCharge = (data) => {
    const filteredCharges = charges.filter((c) => c !== data);
    setCharges(filteredCharges);
    setTotal((prevTotal) => prevTotal - parseFloat(data.charge));
  };

  const handleDiscount = () => {
    if (discount.discount <= 0) {
      setError("Discount must be greater than 0!");
      setDiscountOpen(false);
      return;
    }

    const totalPayments = payments.reduce(
      (acc, payment) => acc + parseFloat(payment.payment) || 0,
      0
    );
    const entryPayments =
      info?.entry?.payments?.reduce(
        (acc, payment) => acc + parseFloat(payment.payment) || 0,
        0
      ) || 0;
    const entryDiscounts =
      info?.entry?.discounts?.reduce(
        (acc, discount) => acc + parseFloat(discount.discount) || 0,
        0
      ) || 0;

    const remainingTotal = (
      total -
      totalPayments -
      entryPayments -
      entryDiscounts
    ).toFixed(2);

    if (parseFloat(remainingTotal) < discount.discount) {
      setError("Discount cannot be greater than Total!");
      setDiscountOpen(false);
      return;
    }
    setDiscounts((prevDiscounts) => [...prevDiscounts, discount]);
    setTotal((prevTotal) => prevTotal - parseFloat(discount.discount));
    setDiscount({ discount: 0.0, discountNote: "" });
    setDiscountOpen(false);
  };
  const removeDiscount = (data) => {
    const filteredDiscounts = discounts.filter((c) => c !== data);
    setDiscounts(filteredDiscounts);
    setTotal((prevTotal) => prevTotal + parseFloat(data.discount));
  };

  const handlePayment = () => {
    if (payment.payment <= 0) {
      setError("Payment must be greater than 0!");
      setPaymentOpen(false);
      return;
    }
    if (payment.payment > total) {
      setError("Payment greater than Total!");
      setPaymentOpen(false);
      return;
    }
    setPayments((prevPayments) => [...prevPayments, payment]);
    setPayment({ payment: 0.0, paymentNote: "" });
    setPaymentOpen(false);
  };
  const removePayment = (data) => {
    const filteredPayments = payments.filter((c) => c !== data);
    setPayments(filteredPayments);
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: info?.entry?.player || playerInitialState,
  });

  const onSubmit = async (data) => {
    if (loading) return;
    setLoading(true);
    try {
      if (info?.entry?.player?.id || selectedPlayer !== "") {
        handlePlayerSelection(data); // Make sure this function handles the player data correctly
        const updatePayload = {
          data: getValues(), // Passing the actual form data
          tournamentId: info.tournament.id,
          selectedEvent, // Ensure selectedEvent is initialized
          playerId:
            selectedPlayer === "" ? info?.entry?.player?.id : selectedPlayer.id,
          payment: {
            total,
            discounts,
            charges,
            payments,
            tournamentFee: info.tournament.tournamentFee,
            remaining: (
              total -
              (payments?.reduce(
                (acc, payment) => acc + parseFloat(payment?.payment || 0),
                0
              ) || 0) -
              (info?.entry?.payments?.reduce(
                (acc, payment) => acc + parseFloat(payment?.payment || 0),
                0
              ) || 0) -
              (info?.entry?.discounts?.reduce(
                (acc, discount) => acc + parseFloat(discount?.discount || 0),
                0
              ) || 0)
            ).toFixed(2),
            memberShip,
          },
          entry: info?.entry,
          club,
        };
        const res = await updatePlayer({
          data: updatePayload,
          id: info.entry?.player?.id,
        });
        if (res?.data?.error === false) {
          toast.success("Entry Updated");
        }
        if (
          res?.error?.data?.error !== "" &&
          res?.error?.data?.error !== undefined
        ) {
          toast.error(res.error.data.error);
        }
      } else {
        const createPayload = {
          data,
          tournamentId: info.tournament.id,
          selectedEvent,
          payment: {
            total,
            discounts,
            charges,
            payments,
            tournamentFee: info.tournament.tournamentFee,
            memberShip,
          },
          club,
        };
        const res = await addNewPlayer(createPayload);
        if (!res?.data?.error) {
          toast.success("New Entry Created");
        }
        if (
          res?.error?.data?.error !== "" &&
          res?.error?.data?.error !== undefined
        ) {
          toast.success(res.error.data.error);
        }
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
      handleCloseForm();
    }
  };

  const handleEvents = (event) => {
    setSelectedEvent((prevSelectedEvent) => {
      let tempTotal = total;

      const isAlreadySelected = prevSelectedEvent.some(
        (selected) => selected.id === event.id
      );

      if (isAlreadySelected) {
        tempTotal -= parseFloat(event.eventFee);
        setTotal(tempTotal);
        return prevSelectedEvent.filter((e) => e.id !== event.id);
      } else {
        tempTotal += parseFloat(event.eventFee);
        setTotal(tempTotal);
        return [...prevSelectedEvent, event];
      }
    });
  };

  const handleMembershipChange = (e) => {
    const selectedFee = parseFloat(e.target.value);
    let tempTotal = total - memberShip + selectedFee;
    setTotal(tempTotal);
    setMemberShip(selectedFee);
  };

  useEffect(() => {
    if (fetchedClubsData) {
      setFetchedClubs(fetchedClubsData.data || []);
    }
  }, [fetchedClubsData]);

  // Fetch initial clubs when the dropdown is opened
  const fetchInitialClubs = useCallback(() => {
    triggerFetchClubs("?limit=10");
  }, [triggerFetchClubs]);

  // Debounced function for searching clubs
  const debouncedFetchClubs = useCallback(
    debounce((term) => {
      if (term.length >= 2) {
        triggerFetchClubs(`?search=${term}&limit=10`);
      }
    }, 500),
    [triggerFetchClubs]
  );

  useEffect(() => {
    return () => {
      debouncedFetchClubs.cancel(); // Clean up the debounce function
    };
  }, [debouncedFetchClubs]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setClub(value);

    if (value.length >= 2) {
      debouncedFetchClubs(value);
    } else if (value.length === 0) {
      fetchInitialClubs();
    }
  };

  const handleDropdownSelect = (selectedClub) => {
    setClub(selectedClub); // Set the input value to the selected club
    setSearchClub(false); // Close the dropdown
  };

  // Fetch initial clubs when dropdown is opened
  useEffect(() => {
    if (searchClub) {
      fetchInitialClubs();
    }
  }, [searchClub, fetchInitialClubs]);

  useEffect(() => {
    if (data) {
      setFilteredPlayers(data.data);
    } else {
      setFilteredPlayers([]);
    }
  }, [data]);

  const handlePlayerSelection = (player) => {
    setValue("firstName", player.firstName);
    setValue("lastName", player.lastName);
    setValue("sex", player.sex);
    const dob = player?.dob;
    let formattedDob = "";

    if (dob?.includes("/")) {
      const [month, day, year] = dob?.split("/");
      if (month && day && year) {
        const formattedMonth = month.length === 1 ? `0${month}` : month;
        const formattedDay = day.length === 1 ? `0${day}` : day;
        formattedDob = `${year}-${formattedMonth}-${formattedDay}`;
      }
    } else {
      formattedDob = dob;
    }
    setValue("dob", formattedDob);
    setValue("homeClub", player.homeClub);
    setValue("phone", player.phone);
    setValue("rating", player.rating);
    setValue("email", player.email);
    setSearchTerm(""); // Clear the search term
    setSeclectedPlayer(player);
    setSelectedEvent(selectedEvent);

    const totalEventFees = selectedEvent.reduce(
      (sum, e) => sum + parseFloat(e.eventFee || 0),
      0
    );

    setTotal(parseFloat(info?.tournament?.tournamentFee || 0) + totalEventFees);
    setIsFocused(false); // Close the dropdown
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-3 sm:p-8 space-y-4">
          <div className="md:flex items-center justify-between">
            <div className="text-center">
              <h1 className="text-lg font-semibold text-blue-600 ">
                {info?.entry?.player?.id ? "Update Player" : "Add New Player"}
              </h1>
              <p className="text-sm text-gray-600 ">
                Please fill in the form below
              </p>
            </div>
            <div className="flex flex-row items-center justify-between gap-3 mt-5 md:mt-0">
              <button
                type="button"
                onClick={handleCloseForm}
                className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-gray-500 rounded-md shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Cancel
              </button>

              <button
                type="submit"
                className="inline-flex items-center px-6 py-2 text-sm font-semibold text-white bg-blue-600 border border-blue-700 rounded-md shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {loading
                  ? "loading.."
                  : info?.entry?.player?.id
                  ? "Save"
                  : "Add"}
              </button>
            </div>
          </div>
          <div className="border-2 border-gray-200 rounded-2xl p-1 sm:p-3 grid grid-cols-9">
            <div className="grid items-center justify-between col-span-9 md:col-span-4 grid-cols-12 gap-4 sm:pr-3 pb-10">
              <div className="col-span-12 font-semibold text-gray-400">
                Player Info
              </div>
              {!info?.entry?.player?.id && (
                <div className="flex flex-col items-start justify-start w-full col-span-12 sm:col-span-8 ">
                  <div className="flex flex-row items-center justify-start px-4 py-2 bg-white border rounded-md w-full">
                    <MagnifyingGlassIcon className="w-5 h-5" />
                    <input
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setTimeout(() => setIsFocused(false), 100)}
                      placeholder="search by name, USATT-ID"
                      type="search"
                      className="px-1 w-full bg-transparent border-none outline-none focus:outline-none"
                    />
                  </div>

                  {isFocused && (
                    <div className="absolute z-10 mt-11 bg-white border border-gray-300 rounded shadow-lg max-h-60 overflow-y-auto">
                      {filteredPlayers?.length === 0 ? (
                        <div className="p-2">No players found</div>
                      ) : (
                        filteredPlayers.map((player) => (
                          <button
                            onMouseDown={(e) => e.preventDefault()} // Prevent blur on mousedown
                            onClick={() => {
                              handlePlayerSelection(player);
                              const selectedEvents =
                                player?.entries?.[0]?.events || [];
                              setSelectedEvent(selectedEvents);
                              const totalEventFees = selectedEvents.reduce(
                                (sum, e) => sum + parseFloat(e.eventFee || 0),
                                0
                              );
                              setMemberShip(
                                parseInt(
                                  player?.entries[0]?.membershipFee || 25
                                )
                              );
                              setTotal(
                                parseFloat(
                                  info?.tournament?.tournamentFee || 0
                                ) +
                                  totalEventFees +
                                  parseInt(
                                    player?.entries[0]?.membershipFee || 25
                                  ) +
                                  (player?.entries?.[0]?.charges?.reduce(
                                    (sum, c) => sum + parseFloat(c.charge),
                                    0
                                  ) || 0)
                              );
                            }}
                            key={player.id}
                            type="button"
                            className="flex justify-between items-center w-[220px] p-1 m-1 bg-gray-100 hover:bg-gray-200"
                          >
                            <div className="p-1">
                              <div className="text-[10px]">
                                #USATT: {player.memberId} - Rating:{" "}
                                {player.rating}
                              </div>
                              <div className="text-sm">
                                {player.firstName} {player.lastName}
                              </div>
                            </div>
                            <div className="h-4 w-4 flex justify-center items-center rounded bg-gray-100">
                              <div className="font-medium text-sm text-black">
                                +
                              </div>
                            </div>
                          </button>
                        ))
                      )}
                    </div>
                  )}
                </div>
              )}
              {selectedPlayer !== "" && (
                <div className="px-3 rounded-lg col-span-8 bg-blue-600 text-white pb-3">
                  <div className="flex justify-end mt-2">
                    <button
                      onClick={() => {
                        setSeclectedPlayer("");
                        setSelectedEvent([]);
                        setMemberShip(0);
                        setTotal(parseFloat(info?.tournament?.tournamentFee));
                        reset();
                      }}
                      className="h-5 w-5 flex justify-center items-center rounded-sm bg-gray-400 hover:bg-gray-300"
                    >
                      x
                    </button>
                  </div>
                  <div className="">
                    <div className="flex text-xs items-center gap-2">
                      <div className=" text-gray-300">
                        Rating:{" "}
                        <span className="text-white font-medium">
                          {selectedPlayer?.rating}
                        </span>
                      </div>
                      <div className=" font-medium text-gray-300">
                        MEMBER ID:{" "}
                        <span
                          className={`${
                            selectedPlayer?.memberId
                              ? "bg-white text-blue-800"
                              : "bg-yellow-500 text-white"
                          }  px-3  font-medium`}
                        >
                          {selectedPlayer?.memberId
                            ? selectedPlayer?.memberId
                            : selectedPlayer?.tempId}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center text-lg gap-2 my-2">
                      <div className=" font-medium">
                        {selectedPlayer?.firstName},
                      </div>
                      <div className=" font-medium">
                        {selectedPlayer?.lastName}
                      </div>
                    </div>
                    {membershipExpiryDate && (
                      <>
                        <div className="flex text-xs items-center gap-2">
                          <div className="text-gray-300">
                            Membership Expiry:
                          </div>
                          {currentDate > membershipExpiryDate ? (
                            <div className="font-medium bg-red-600 px-2">
                              {selectedPlayer?.membershipExpiryDate}
                            </div>
                          ) : (
                            <div className="font-medium">
                              {selectedPlayer?.membershipExpiryDate}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              <>
                <div className="flex flex-col items-start justify-start w-full col-span-6">
                  <label
                    htmlFor="firstName"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    First Name
                  </label>
                  <input
                    className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                    id="firstName"
                    {...register("firstName", {
                      required: "First Name is required",
                    })}
                  />
                  <FormFieldError errors={errors} field={"firstName"} />
                </div>

                <div className="flex flex-col items-start justify-start w-full col-span-6">
                  <label
                    htmlFor="lastName"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Last Name
                  </label>
                  <input
                    id="lastName"
                    className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                    {...register("lastName", {
                      required: "Last Name is required",
                    })}
                  />

                  <FormFieldError errors={errors} field={"lastName"} />
                </div>

                <div className="flex flex-col items-start justify-start w-full col-span-6">
                  <label
                    htmlFor="sex"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Gender
                  </label>
                  <select
                    className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                    id="sex"
                    {...register("sex", {
                      required: "Gender is required",
                    })}
                  >
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </select>
                  <FormFieldError errors={errors} field={"sex"} />
                </div>
                <div className="flex flex-col items-start justify-start w-full col-span-6">
                  <label
                    htmlFor="dob"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    DOB
                  </label>
                  <input
                    className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                    id="dob"
                    type="date"
                    max={new Date().toISOString().split("T")[0]}
                    {...register("dob", {
                      required: " D.O.B is required",
                    })}
                  />
                  <FormFieldError
                    errors={errors?.entryRestrictions}
                    field={"dob"}
                  />
                </div>
                <div className="flex flex-col items-start justify-start w-full col-span-6 relative">
                  <label
                    htmlFor="homeClub"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Home Club
                  </label>
                  <input
                    type="search"
                    id="homeClub"
                    value={
                      typeof club === "string" ? club : club?.clubName || ""
                    }
                    onChange={handleInputChange}
                    onFocus={() => setSearchClub(true)}
                    onBlur={() => setTimeout(() => setSearchClub(false), 200)} // Delay closing to allow click on dropdown
                    className="w-full col-span-8 border-2 bg-transparent py-1.5 p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm rounded-sm sm:leading-6"
                    placeholder="Search for a club..."
                  />
                  {searchClub && (
                    <div className="absolute w-full mt-16 bg-white border rounded shadow z-10">
                      {isLoading ? (
                        <p className="p-2 text-gray-500">Loading...</p>
                      ) : (
                        fetchedClubs.map((c, i) => (
                          <div
                            key={i}
                            onClick={() => handleDropdownSelect(c)}
                            className="p-2 cursor-pointer hover:bg-gray-100"
                          >
                            {c.clubName}
                          </div>
                        ))
                      )}
                    </div>
                  )}
                </div>

                <div className="flex flex-col items-start justify-start w-full col-span-6">
                  <label
                    htmlFor="phone"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Phone
                  </label>
                  <input
                    className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                    id="phone"
                    type="tel"
                    {...register("phone")}
                  />
                  <FormFieldError errors={errors} field={"phone"} />
                </div>

                <div className="flex flex-col items-start justify-start w-full col-span-6">
                  <label
                    htmlFor="email"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Email
                  </label>
                  <input
                    disabled={info?.entry?.player?.id}
                    className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                    id="email"
                    type="email"
                    {...register("email")}
                  />
                  <FormFieldError errors={errors} field={"email"} />
                </div>
                <div className="flex flex-col items-start justify-start w-full col-span-6">
                  <label
                    htmlFor="rating"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Rating
                  </label>
                  <input
                    className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                    id="rating"
                    type="number"
                    {...register("rating")}
                  />
                  <FormFieldError errors={errors} field={"rating"} />
                </div>
              </>
            </div>
            <div className="col-span-9 md:col-span-2 px-3 md:border-x-2 ">
              <div className="col-span-12 font-semibold text-gray-400">
                Select Event
              </div>

              {info?.events?.map((event, i) => (
                <div
                  className="w-full flex mt-5 gap-3 border-b pb-3"
                  key={event.id}
                >
                  <div>
                    <input
                      type="checkbox"
                      checked={selectedEvent.some((e) => e.id === event.id)}
                      onChange={() => handleEvents(event)}
                    />
                  </div>
                  <div>
                    <div className="text-lg font-medium">
                      {i + 1}. {event.eventName}
                    </div>
                    <div className="text-xs text-gray-600">
                      {event.startDate.split("T")[0]} . {event.startTime}
                    </div>
                    <div className="text-xs text-gray-800">
                      Entry Fee: ${event.eventFee}
                    </div>
                    <div
                      className={`text-xs text-gray-800 w-16 text-center ${
                        event.entry ? "bg-blue-100" : "bg-gray-200"
                      }`}
                    >
                      {event.entry ? "OPEN" : "CLOSED"}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-10 sm:mt-0 col-span-9 md:col-span-3 pl-3">
              <div className="font-semibold text-gray-400">Payment Details</div>
              <div className="mt-5 text-sm">
                <div className="flex justify-between">
                  <div className="text-gray-500">Tournament Fee:</div>
                  <div className="font-medium">
                    ${info?.tournament?.tournamentFee}
                  </div>
                </div>
                <div>
                  {selectedEvent?.map((event, i) => (
                    <div key={i} className="flex justify-between mt-1">
                      <div className="text-gray-500">{event?.eventName}</div>
                      <div className="font-medium">${event?.eventFee}</div>
                    </div>
                  ))}
                </div>
                {charge > 0 && (
                  <div className="flex justify-between mt-1">
                    <div className="text-gray-500">Manual Charge</div>
                    <div className="font-medium">${charge}</div>
                  </div>
                )}
                {discount > 0 && (
                  <div className="flex justify-between mt-1">
                    <div className="text-gray-500">Discount</div>
                    <div className="font-medium">${discount}</div>
                  </div>
                )}
                {info?.tournament?.membershipSystem === "usa-tt" ? (
                  <div className="flex flex-col items-start justify-start w-full mt-3">
                    <label
                      htmlFor="memberShip"
                      className="block col-span-4 text-[10px] font-medium leading-6 text-gray-500"
                    >
                      USATT MEMBERSHIP FEE
                    </label>
                    <select
                      className="w-full col-span-8 border-2 bg-transparent py-1.5 p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm rounded-sm sm:leading-6"
                      id="memberShip"
                      value={memberShip}
                      onChange={handleMembershipChange}
                    >
                      <option value="0">None</option>
                      <option value="50">
                        USATT Adult Tournament Pass $50
                      </option>
                      <option value="20">
                        USATT Junior Tournament Pass $20
                      </option>
                      <option value="25">USATT Adult Basic Plan $25</option>
                      <option value="75">USATT Pro Plan $75</option>
                      <option value="1300">USATT Lifetime Plan $1300</option>
                    </select>
                  </div>
                ) : (
                  <div className="flex flex-col items-start justify-start w-full mt-3">
                    <label
                      htmlFor="memberShip"
                      className="block col-span-4 text-[10px] font-medium leading-6 text-gray-500"
                    >
                      MEMBERSHIP
                    </label>
                    <select
                      className="w-full col-span-8 border-2 bg-transparent py-1.5 p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm rounded-sm sm:leading-6"
                      id="memberShip"
                      value={memberShip}
                      onChange={handleMembershipChange}
                    >
                      <option value="0">None</option>
                    </select>
                  </div>
                )}
                <div className="mt-2 space-y-1 opacity-50">
                  {info?.entry?.discounts?.map((discount, i) => (
                    <div
                      key={i}
                      className="flex justify-between items-center px-3 border rounded text-sm py-1 bg-green-600"
                    >
                      <div className="flex gap-2">
                        <div className="text-gray-100">Discount -</div>
                        <div className="text-gray-100">
                          {discount.discountNote}:
                        </div>
                        <div className="text-white font-medium">
                          {" "}
                          ${discount.discount}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-2 space-y-1 opacity-50">
                  {info?.entry?.charges?.map((charge, i) => (
                    <div
                      key={i}
                      className="flex justify-between items-center px-3 border rounded text-sm py-1 bg-red-700"
                    >
                      <div className="flex gap-3">
                        <div className="text-gray-100">Charge -</div>
                        <div className="text-gray-100">
                          {charge.chargeNote}:
                        </div>
                        <div className="text-white font-medium">
                          {" "}
                          ${charge.charge}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-2 space-y-1 opacity-50">
                  {info?.entry?.payments?.map((payment, i) => (
                    <div
                      key={i}
                      className="flex justify-between items-center px-3 border rounded text-sm py-1 bg-blue-700"
                    >
                      <div className="flex gap-3">
                        <div className="text-gray-100">Payment -</div>
                        <div className="text-gray-100">
                          {payment.paymentNote}:
                        </div>
                        <div className="text-white font-medium">
                          {" "}
                          ${payment.payment}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-5 space-y-1">
                  {discounts?.map((discount, i) => (
                    <div
                      key={i}
                      className="flex justify-between items-center px-3 border rounded text-sm py-1 bg-green-700"
                    >
                      <div className="flex gap-2">
                        <div className="text-gray-100">Discount -</div>
                        <div className="text-gray-100">
                          {discount.discountNote}:
                        </div>
                        <div className="text-white font-medium">
                          {" "}
                          ${discount.discount}
                        </div>
                      </div>
                      <button
                        type="button"
                        onClick={() => removeDiscount(discount)}
                        className="text-base font-medium px-2 bg-gray-100 hover:bg-gray-200 text-green-600 rounded"
                      >
                        x
                      </button>
                    </div>
                  ))}
                </div>
                <div className="mt-3 space-y-1">
                  {charges?.map((charge, i) => (
                    <div
                      key={i}
                      className="flex justify-between items-center px-3 border rounded text-sm py-1 bg-red-700"
                    >
                      <div className="flex gap-3">
                        <div className="text-gray-100">Charge -</div>
                        <div className="text-gray-100">
                          {charge.chargeNote}:
                        </div>
                        <div className="text-white font-medium">
                          {" "}
                          ${charge.charge}
                        </div>
                      </div>
                      <button
                        type="button"
                        onClick={() => removeCharge(charge)}
                        className="text-base font-medium px-2 bg-gray-100 hover:bg-gray-200 text-red-600 rounded"
                      >
                        x
                      </button>
                    </div>
                  ))}
                </div>
                <div className="mt-3 space-y-1">
                  {payments?.map((payment, i) => (
                    <div
                      key={i}
                      className="flex justify-between items-center px-3 border rounded text-sm py-1 bg-blue-700"
                    >
                      <div className="flex gap-3">
                        <div className="text-gray-100">Payment -</div>
                        <div className="text-gray-100">
                          {payment.paymentNote}:
                        </div>
                        <div className="text-white font-medium">
                          {" "}
                          ${payment.payment}
                        </div>
                      </div>
                      <button
                        type="button"
                        onClick={() => removePayment(payment)}
                        className="text-base font-medium px-2 bg-gray-100 hover:bg-gray-200 text-blue-600 rounded"
                      >
                        x
                      </button>
                    </div>
                  ))}
                </div>
                <div className="flex justify-between mt-5 border-t pt-3">
                  <div className="font-medium">Total:</div>
                  <div className="font-medium">${total}</div>
                </div>
                {payments.length > 0 && (
                  <div className="flex justify-between mt-1">
                    <div className="text-gray-500">Payment</div>
                    {/* Calculate the total sum of payments */}
                    <div className="font-medium">
                      $
                      {payments
                        .reduce(
                          (acc, payment) => acc + parseFloat(payment.payment),
                          0
                        )
                        .toFixed(2)}
                    </div>
                  </div>
                )}
                <div className="flex justify-between mt-2">
                  <div className="font-light">Remaining:</div>
                  <div className="font-medium text-red-600">
                    $
                    {(
                      total -
                      (payments.reduce(
                        (acc, payment) => acc + parseFloat(payment.payment),
                        0
                      ) || 0) -
                      (info?.entry?.payments?.reduce(
                        (acc, payment) => acc + parseFloat(payment.payment),
                        0
                      ) || 0) -
                      (info?.entry?.discounts?.reduce(
                        (acc, discount) => acc + parseFloat(discount.discount),
                        0
                      ) || 0)
                    ).toFixed(2)}
                  </div>
                </div>
                <div className="h-[1px] bg-gray-400 w-full my-5"></div>
                <div className="flex justify-around text-white text-xs lg:text-sm">
                  <button
                    type="button"
                    onClick={() => setChargeOpen(true)}
                    className="bg-blue-700 hover:shadow-lg hover:bg-blue-600 border shadow px-3 py-2 rounded"
                  >
                    + Charge
                  </button>
                  <button
                    type="button"
                    onClick={() => setDiscountOpen(true)}
                    className="bg-blue-700 hover:shadow-lg hover:bg-blue-600 border shadow px-3 py-2 rounded"
                  >
                    + Discount
                  </button>
                  <button
                    type="button"
                    onClick={() => setPaymentOpen(true)}
                    className="bg-blue-700 hover:shadow-lg hover:bg-blue-600 border shadow px-3 py-2 rounded"
                  >
                    + Payment
                  </button>
                </div>
                <div className="mt-5">
                  {error && (
                    <div className="flex justify-between items-center px-3 border rounded text-sm py-1 border-red-400">
                      <div className="flex gap-3 text-red-600">{error}</div>
                      <button
                        type="button"
                        onClick={() => setError("")}
                        className="text-base font-medium px-2 bg-gray-100 hover:bg-gray-200 text-red-400 rounded"
                      >
                        x
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {openCharge && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-gray-200 p-5 rounded shadow-lg backdrop-blur-sm ">
            <div className="w-full flex justify-end mb-5">
              <button
                type="button"
                onClick={() => {
                  setChargeOpen(false);
                }}
                className="text-xl self-end flex font-medium px-2 rounded bg-gray-400"
              >
                x
              </button>
            </div>
            <div>
              <input
                type="text"
                name="chargeNote"
                value={charge.chargeNote}
                onChange={postCharge}
                placeholder="Add Note"
                className=" w-full px-3 py-2 mb-5 rounded border border-gray-400 shadow"
              />
            </div>
            <div className="flex gap-3">
              <input
                type="number"
                name="charge"
                min={0}
                value={charge.charge}
                onChange={postCharge}
                placeholder="Charge Amount"
                className="w-full col-span-8 text-sm border border-gray-400 bg-white rounded-md py-2 px-3  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 "
              />
              <button
                type="button"
                onClick={handleCharge}
                className="text-xs whitespace-nowrap px-3 py-2 bg-blue-700 text-white font-medium rounded"
              >
                Add Charge
              </button>
            </div>
          </div>
        </div>
      )}
      {openDiscount && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-gray-200 p-5 rounded shadow-lg backdrop-blur-sm ">
            <div className="w-full flex justify-end mb-5">
              <button
                type="button"
                onClick={() => {
                  setDiscountOpen(false);
                }}
                className="text-xl self-end flex font-medium px-2 rounded bg-gray-400"
              >
                x
              </button>
            </div>
            <div>
              <input
                type="text"
                name="discountNote"
                value={discount.discountNote}
                onChange={postDiscount}
                placeholder="Add Note"
                className=" w-full px-3 py-2 mb-5 rounded border border-gray-400 shadow"
              />
            </div>
            <div className="flex gap-3">
              <input
                type="number"
                name="discount"
                min={0}
                max={total}
                value={discount.discount}
                onChange={postDiscount}
                placeholder="Discount Amount"
                className="w-[150px] text-sm border border-gray-400 bg-white rounded-md py-2 px-3  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 "
              />
              <button
                type="button"
                onClick={handleDiscount}
                className="text-xs whitespace-nowrap px-3 py-2 bg-blue-700 text-white font-medium rounded"
              >
                Add Discount
              </button>
            </div>
          </div>
        </div>
      )}
      {openPayment && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-gray-200 p-5 rounded shadow-lg backdrop-blur-sm ">
            <div className="w-full flex justify-end mb-5">
              <button
                type="button"
                onClick={() => {
                  setPaymentOpen(false);
                }}
                className="text-xl self-end flex font-medium px-2 rounded bg-gray-400"
              >
                x
              </button>
            </div>
            <div>
              <input
                type="text"
                name="paymentNote"
                value={payment.paymentNote}
                onChange={postPayment}
                placeholder="Add Note"
                className=" w-full px-3 py-2 mb-5 rounded border border-gray-400 shadow"
              />
            </div>
            <div className="flex gap-3">
              <input
                type="number"
                name="payment"
                min={0}
                value={payment.payment}
                onChange={postPayment}
                placeholder="Charge Payment"
                className="w-full col-span-8 text-sm border border-gray-400 bg-white rounded-md py-2 px-3  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 "
              />
              <button
                type="button"
                onClick={handlePayment}
                className="text-xs whitespace-nowrap px-3 py-2 bg-blue-700 text-white font-medium rounded"
              >
                Add Payment
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddPlayerForm;
