import React, { useEffect, useState } from "react";
import { useGetQueryTournamentsQuery } from "../../../redux/features/api/apiSlice";
import { useSelector } from "react-redux";
import {
  Squares2X2Icon,
  Bars2Icon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import { TournamentCard } from "../../../components";
import loading from "../../../images/loading-image.svg";
import { Link } from "react-router-dom";

const TournamentsPage = () => {
  const [activeTab, setActiveTab] = useState("active");
  const [search, setSearch] = useState("");
  let status = "published";
  const { isFetching, refetch } = useGetQueryTournamentsQuery(
    new URLSearchParams({
      activeTab: activeTab,
      search: search,
      status: status,
    }).toString()
  );

  const { queryTournaments } = useSelector((state) => state.tournaments);
  const [dataView, setDataView] = useState("grid");

  const handleTabChange = () => {
    setActiveTab(activeTab === "active" ? "past" : "active");
  };

  useEffect(() => {
    refetch();
  }, [activeTab, search]);

  return (
    <>
      <div className=" px-3">
        <div className="min-h-screen w-full">
          <div className="flex  justify-between py-6">
            <div className="flex flex-row items-center  py-4 ">
              <div className="divide-x-2 text-[10px] sm:text-sm">
                <button
                  onClick={handleTabChange}
                  className={`px-2 sm:px-4 py-2  uppercase rounded-s-md ${
                    activeTab === "active"
                      ? "bg-blue-900 text-white font-medium"
                      : "bg-white text-black"
                  }`}
                >
                  active
                </button>
                <button
                  onClick={handleTabChange}
                  className={`px-2 sm:px-4 py-2  uppercase rounded-e-md ${
                    activeTab === "past"
                      ? "bg-blue-900 text-white font-medium"
                      : "bg-white text-black"
                  }`}
                >
                  past
                </button>
              </div>
            </div>
            <div className="flex flex-row items-center gap-2 py-4">
              <div className="flex flex-row items-center justify-start px-1 sm:px-4 py-1 sm:py-2 bg-white border rounded-md ">
                <MagnifyingGlassIcon className="h-4 w-4 sm:w-5 sm:h-5" />
                <input
                  type="search"
                  onChange={(e) => setSearch(e.target.value)}
                  className="px-1 bg-transparent border-none outline-none focus:outline-none"
                />
              </div>
              <div className="hidden sm:block">
                <button
                  onClick={() =>
                    setDataView(dataView === "grid" ? "tabel" : "grid")
                  }
                  className={`px-2 sm:px-4 py-1 sm:py-2  uppercase rounded-s-md ${
                    dataView === "grid"
                      ? "bg-blue-900 text-white font-medium"
                      : "bg-white text-black"
                  }`}
                >
                  <Squares2X2Icon className="h-3 w-3 sm:w-5 sm:h-5" />
                </button>
                <button
                  onClick={() =>
                    setDataView(dataView === "tabel" ? "grid" : "tabel")
                  }
                  className={`px-2 sm:px-4 py-1 sm:py-2  uppercase rounded-e-md ${
                    dataView === "tabel"
                      ? "bg-blue-900 text-white font-medium"
                      : "bg-white text-black"
                  }`}
                >
                  <Bars2Icon className="h-3 w-3 sm:w-5 sm:h-5 " />
                </button>
              </div>
            </div>
          </div>
          {isFetching ? (
            <div className=" h-[500px] w-full flex justify-center items-center">
              <img className="-mt-32" src={loading} alt="" />
            </div>
          ) : queryTournaments?.length < 1 ? (
            <div className="text-center mt-20 font-medium text-gray-500">
              No Tournaments Available
            </div>
          ) : (
            <div
              className={` grid gap-4  ${
                activeTab === "grid" ? "grid-cols-4" : "grid-cols-12"
              }`}
            >
              {queryTournaments?.map((tournament, index) => {
                return (
                  <div
                    key={index}
                    className={`p-6 text-white bg-gradient-to-r from-cyan-900  to-blue-800 border border-gray-300 rounded-md shadow-sm ${
                      dataView === "grid"
                        ? "col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3"
                        : "col-span-12"
                    }`}
                  >
                    <TournamentCard tournament={tournament} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className=" max-h-[250px] flex flex-col justify-center items-center gap-2 py-5 text-white bg-gradient-to-r from-cyan-900  to-blue-800 border border-gray-300 rounded-lg">
          <div className="flex gap-3">
            {" "}
            <Link to="/display" className=" font-bold">
              Display
            </Link>
            <span>|</span>
            <Link to="/matchreporter" className=" font-bold">
              Match Reporter
            </Link>
          </div>
          <div className="mt-10 font-medium text-gray-100">
            What is PORTALPONG?
          </div>
          <div className="text-center max-w-xl text-sm text-gray-200">
            PORTALPONG is the competition platform you've been
            waiting for. For tournaments, membership systems, and more.
            Manage all your competition needs in one simple, modern, and
            intuitive platform.
          </div>
        </div>
      </div>
    </>
  );
};

export default TournamentsPage;
