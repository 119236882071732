import React from "react";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <p>Page Not Found 404</p>
    </div>
  );
};

export default NotFound;
