import React, { useEffect, useState } from "react";
import { classNames } from "../../utils/classNames";
import { ChevronDownIcon, SquaresPlusIcon } from "@heroicons/react/16/solid";
import { useParams } from "react-router-dom";
import {
  useGetTournamentTablesQuery,
  useGroupTablesMutation,
} from "../../redux/features/api/apiSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Matches from "../commons/Matches";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";

const Stading = ({ players, matches, groupId, roundRobbin }) => {
  const qualify = roundRobbin?.noAdvance;

  const playersWithStats = players.map((player) => {
    const playerStats = matches.reduce(
      (stats, match) => {
        const matchPlayer = match.matchPlayers.find(
          (p) => p.id === player.playerId
        );

        if (!matchPlayer || match.groupId !== groupId || !match.result) {
          return stats;
        }

        const isPlayerA = matchPlayer.PlayerMatch.number === 0;

        const playerAScore = match.result.A;
        const playerBScore = match.result.B;

        const wonMatch = isPlayerA
          ? playerAScore > playerBScore
          : playerBScore > playerAScore;

        const points = wonMatch ? 3 : 0;
        const roundDifference = isPlayerA
          ? playerAScore - playerBScore
          : playerBScore - playerAScore;

        stats.totalMatches += 1;
        stats.totalPoints += points;
        stats.roundDifference += roundDifference;
        stats.wins += wonMatch ? 1 : 0;
        stats.losses += wonMatch ? 0 : 1;

        return stats;
      },
      {
        totalMatches: 0,
        wins: 0,
        losses: 0,
        roundDifference: 0,
        totalPoints: 0,
      }
    );

    return {
      ...player,
      playerStats,
    };
  });

  // Sort players by stats
  const sortedPlayers = playersWithStats.sort((a, b) => {
    if (b.playerStats.totalPoints !== a.playerStats.totalPoints) {
      return b.playerStats.totalPoints - a.playerStats.totalPoints;
    }
    if (b.playerStats.roundDifference !== a.playerStats.roundDifference) {
      return b.playerStats.roundDifference - a.playerStats.roundDifference;
    }
    if (b.playerStats.totalMatches !== a.playerStats.totalMatches) {
      return b.playerStats.totalMatches - a.playerStats.totalMatches;
    }
    return a.rating - b.rating; // Fallback to player rating
  });

  return (
    <div className="px-4 py-2 space-y-4 overflow-y-scroll divide-y max-h-[350px] border mx-3 mb-3 rounded-lg">
      {sortedPlayers.map((player, i) => (
        <div
          className="flex flex-row items-center justify-between gap-4"
          key={i}
        >
          <div className="flex items-center gap-3">
            <div
              className={`w-6 h-6 text-center text-white ${
                i < qualify ? "bg-green-500" : "bg-gray-300"
              }`}
            >
              {i + 1}
            </div>
            <div className="flex flex-col">
              <div>
                <span className="text-xs lg:text-sm font-bold">
                  {player?.player?.rating}
                </span>
                <span>-</span>
                <span className="text-xs lg:text-sm">
                  {player?.player?.club?.location || player?.player?.homeAddress}
                </span>
              </div>
              <span>
                <span className="text-xs lg:text-sm font-bold">
                  {player?.player?.lastName}, {player?.player?.firstName}
                </span>
              </span>
            </div>
            <div className="text-xs h-5 w-5 rounded-full bg-blue-300 flex justify-center items-center text-white font-medium">{player.order === 0 ? "A" : player.order === 1 ? "B" : player.order === 2 ? "C" : player.order === 3 ? "D" : player.order === 4 ? "E" : player.order === 5 ? "F" : player.order === 6 ? "G" : player.order === 7 ? "H" :  player.order+1 }</div>
          </div>

          <div className="py-2 ">
            <div className="grid items-center justify-center grid-cols-5 grid-rows-2 gap-4 text-center">
              <span className="text-xs">M</span>
              <span className="text-xs">W</span>
              <span className="text-xs">L</span>
              <span className="text-xs">RD</span>
              <span className="text-xs">Pts</span>
              <span className="text-xs">
                {player.playerStats.totalMatches || 0}
              </span>
              <span className="text-xs">{player.playerStats.wins || 0}</span>
              <span className="text-xs">{player.playerStats.losses || 0}</span>
              <span className="text-xs">
                {player.playerStats.roundDifference || 0}
              </span>
              <span className="text-xs">
                {player.playerStats.totalPoints || 0}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const GroupCard = ({ group, tournament, roundRobbin, admin }) => {

  let { tournamentId } = useParams();
  const [groupTables] = useGroupTablesMutation();
  useGetTournamentTablesQuery(tournamentId || tournament, { skip: !admin });
  const { tables } = useSelector((state) => state.tables) || [];
  const [editTable, setEditTable] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTable, setSelectedTable] = useState(group?.groupTables);

  const handleTables = (table) => {
    setSelectedTable((prevSelected) => {
      if (prevSelected.some((t) => t.index === table.index)) {
        return prevSelected.filter((t) => t.index !== table.index);
      } else {
        return [...prevSelected, table];
      }
    });
  };

  const handleGroupTables = async (id) => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await groupTables({ id, data: selectedTable });
      if (res?.data?.error === false) {
        toast.success(res.data.data);
        setEditTable(false);
      }
      if (res?.error?.data?.error !== undefined) {
        toast.success(res.error.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const tabs = [
    {
      name: "stadings",
      href: "#",
      element: () => (
        <Stading
          players={group?.groupPlayers}
          matches={group?.matches}
          groupId={group.id}
          roundRobbin={roundRobbin}
        />
      ),
    },
    {
      name: "matches",
      href: "#",
      element: () => <Matches matches={group?.matches} admin={admin} />,
    },
  ];

  const [activeTab, setActiveTab] = useState("stadings"); // stadings | matches
  const handleActiveTabChange = () => {
    setActiveTab(activeTab === "stadings" ? "matches" : "stadings");
  };

  return (
    <div className="flex flex-col items-start justify-start overflow-hidden bg-white rounded-md border shadow">
      <div className="flex flex-row items-center justify-between w-full px-4 py-5 text-white bg-blue-900 text-sm md:text-base">
        <h2 className="font-bold ">Group {group?.number + 1}</h2>
        <div className="flex gap-3 text-gray-200 font-medium">
          <div>Matches</div>
          <div className="flex">
            <div>{group?.matches?.filter((m) => m.result !== "")?.length}</div>
            <span>/</span>
            <div>{group?.matches?.length}</div>
          </div>
          {group?.matches?.length ===
            group?.matches?.filter((m) => m.result !== "")?.length && (
            <div title="GROUP COMPLETED" className="flex items-center">
              <CheckBadgeIcon
                className=" text-green-400"
                height={20}
                width={20}
              />
            </div>
          )}
        </div>
        <div className="flex items-center gap-3">
          {!editTable ? (
            <div className="flex items-center gap-3">
              {admin && (
                <button onClick={() => setEditTable(true)}>
                  <SquaresPlusIcon height={20} width={20} />
                </button>
              )}

              <div className="px-3 py-2 rounded bg-white text-black text-sm font-medium">
                {group?.groupTables?.length < 1 ? (
                  "No Table"
                ) : (
                  <>
                    Table{" "}
                    {group.groupTables
                      .slice() // Create a shallow copy of the array
                      .sort((a, b) => a.index - b.index) // Sort the copied array
                      .map((table, i) => (
                        <span key={i}>
                          {table.index}
                          {group?.groupTables?.length !== i + 1 && ", "}
                        </span>
                      ))}
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <button
                className="text-lg font-medium bg-white rounded h-6 w-6 text-black"
                onClick={() => setEditTable(false)}
              >
                X
              </button>
              <button
                onClick={() => setShowTable(!showTable)}
                className="flex gap-1 items-center py-2 bg-white text-black rounded px-2 text-sm"
              >
                {selectedTable?.length < 1
                  ? "No Table"
                  : selectedTable
                      .slice() // Create a shallow copy of the array
                      .sort((a, b) => a.index - b.index) // Sort the copied array
                      .map((table, i) => (
                        <span key={i}>
                          {table.index} {selectedTable.length !== i + 1 && ","}
                        </span>
                      ))}
                <ChevronDownIcon height={14} width={14} />
              </button>
              {showTable && tables.length > 0 && (
                <div className="absolute z-10 mt-[290px] space-y-2 border shadow bg-white max-h-[250px] overflow-y-scroll rounded-lg p-3">
                  {selectedTable?.length > 0 && (
                    <button
                      onClick={() => setSelectedTable([])}
                      className="text-black text-sm text-center w-full hover:bg-gray-100 py-1"
                    >
                      Reset
                    </button>
                  )}
                  {tables?.map((table, i) => {
                    const isSelected = selectedTable.some(
                      (t) => t.id === table.id
                    );
                    return (
                      <div key={i} className="text-black text-sm">
                        <button
                          onClick={() => handleTables(table)}
                          className={`flex gap-2 px-1 py-1 ${
                            isSelected ? "bg-gray-200" : "hover:bg-gray-200"
                          }`}
                        >
                          Table {table.index}{" "}
                          <span className="bg-blue-300 px-2">
                            {table?.tableNumber}
                          </span>
                        </button>
                      </div>
                    );
                  })}
                </div>
              )}
              <button
                onClick={() => handleGroupTables(group.id)}
                className="bg-blue-500 px-3 py-2 text-sm rounded shadow"
              >
                Apply
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="mt-2 ">
        <div className="">
          <nav className="flex ">
            {tabs.map((tab, i) => (
              <button
                onClick={handleActiveTabChange}
                key={i}
                className={classNames(
                  activeTab === tab.name
                    ? "text-gray-950 font-bold"
                    : "text-gray-500 font-normal",
                  "px-4 py-2 whitespace-nowrap  pb-4 text-sm font-medium capitalize"
                )}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div className="w-full">
        {tabs.map((tab, i) => {
          if (tab.name === activeTab) {
            return <div key={i}>{tab.element()}</div>;
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default GroupCard;
