import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  SquaresPlusIcon,
  ArchiveBoxXMarkIcon,
} from "@heroicons/react/24/solid";
import AddPlayerForm from "../../../pages/admin/Players/AddPlayerForm";
import { useSelector } from "react-redux";
import {
  useEntryCheckinMutation,
  useEntryNoteMutation,
  useEventRemovePlayerMutation,
  useGetEventsByTournamentIdQuery,
  useGetTournamentByIdQuery,
  useResetDrawMutation,
} from "../../../redux/features/api/apiSlice";
import { toast } from "react-toastify";
import { format, eachDayOfInterval, parseISO } from "date-fns";

const handleExportPlayers = async ({ entries }) => {
  const headers = [
    "firstName",
    "lastName",
    "gender",
    "birthDate",
    "phone",
    "email",
    "address",
    "city",
    "state",
    "zipCode",
    "homeClub",
  ];

  const csvContent = [
    headers.join(","), // CSV header
    ...entries.map((entry) =>
      [
        entry.player.firstName,
        entry.player.lastName,
        entry.player.sex,
        entry.player.dob,
        entry.player.phone,
        entry.player.email,
        entry.player.address1,
        entry.player.city,
        entry.player.state,
        entry.player.zip,
        entry.player.homeClub,
      ].join(",")
    ),
  ].join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `players.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const getDatesInRange = (startDate, endDate) => {
  if (!startDate || !endDate) return []; // Return an empty array if either date is missing

  return eachDayOfInterval({
    start: parseISO(startDate),
    end: parseISO(endDate),
  });
};

const AdminPlayersTab = ({ entries, tournamentLevel, eventLevel }) => {
  const { tournamentId, eventId } = useParams();
  const [resetDraw] = useResetDrawMutation();
  const { draw } = useSelector((state) => state.draws);
  useGetTournamentByIdQuery(tournamentId);
  const { tournament } = useSelector((state) => state.tournaments);
  useGetEventsByTournamentIdQuery(tournamentId);
  const { events } = useSelector((state) => state.events);
  const [removeEventPlayer] = useEventRemovePlayerMutation();
  const [entryCheckin] = useEntryCheckinMutation();
  const [playerForm, setPlayerForm] = useState(false);
  const [entry, setEntry] = useState("");
  const [isToggle, setIsToggle] = useState(false);
  const [exportPlayer, setExportPlayer] = useState(false);
  const [remove, setRemove] = useState(false);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState("");
  const [data, setData] = useState("");
  const [notes, setNotes] = useState({}); // State to store notes for each entry
  const [debouncedNotes, setDebouncedNotes] = useState({});
  const [entryNote] = useEntryNoteMutation();
  const [checkin, setCheckin] = useState(false);
  const dates = getDatesInRange(tournament?.startDate, tournament?.endDate);

  const handleToggleChange = async (data, date) => {
    try {
      const res = await entryCheckin({ data, date });
      if (!res.data.error) {
        toast.info(`Checkin Updated`);
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setIsToggle(!isToggle);
    }
  };

  const handleRemovePlayer = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const id = eventLevel ? eventId : tournamentId;
      if (tournamentLevel) {
        data.tournamentLevel = true;
        data.eventLevel = false;
      } else {
        data.tournamentLevel = false;
        data.eventLevel = true;
      }
      const res = await removeEventPlayer({ id, data });
      if (res?.data?.error === false) {
        if (eventLevel) {
          await resetDraw(draw.id);
        }
        toast.info(
          `Player Removed From ${tournamentLevel ? "Tournament" : "Event"}`
        );
      }
      if (res?.error?.data?.error !== undefined) {
        toast.warning(res.error.data.error);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
      setIndex("");
      setRemove(false);
      setData("");
    }
  };

  const handleUpdateNote = async (id) => {
    try {
      const res = await entryNote({
        id,
        data: { noteText: debouncedNotes[id] },
      });
      if (res?.data?.error === false) {
        toast.success("Note updated!");
      } else if (res?.error?.data?.error !== undefined) {
        toast.warning(res.error.data.error);
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    }
  };

  const closePlayerForm = () => {
    setPlayerForm(false);
  };

  const openPlayerForm = (data) => {
    setEntry(data);
    setPlayerForm(true);
  };

  const exportPlayers = async () => {
    if (exportPlayer) return;
    setExportPlayer(true);
    try {
      await handleExportPlayers({ entries });
      toast.success("Players Exported");
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setExportPlayer(false);
    }
  };

  useEffect(() => {
    const timeouts = Object.keys(notes).reduce((acc, id) => {
      acc[id] = setTimeout(() => {
        setDebouncedNotes((prev) => ({
          ...prev,
          [id]: notes[id],
        }));
      }, 3000);
      return acc;
    }, {});

    return () => {
      Object.values(timeouts).forEach(clearTimeout);
    };
  }, [notes]);

  // Trigger API call when debouncedNotes for an entry changes
  useEffect(() => {
    Object.keys(debouncedNotes).forEach((id) => {
      if (debouncedNotes[id]?.trim() !== "") {
        handleUpdateNote(id);
      }
    });
  }, [debouncedNotes]);

  return (
    <>
      {remove && (
        <div
          className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay"
          onClick={() => {
            setRemove(false);
            setIndex("");
            setData("");
          }}
        >
          <div
            className="bg-white rounded-lg p-5"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="text-lg text-gray-800 text-center font-bold">
              Are you sure?
            </div>
            <div className="text-sm text-gray-500 text-center max-w-md mt-5">
              Player will not be included in the new Draw and player transaction
              will be lost.
            </div>
            <div className="flex justify-center mt-10 gap-10 text-white font-medium">
              <button
                className="text-sm px-5 py-2 rounded bg-gray-400 hover:bg-gray-500 border"
                onClick={() => {
                  setRemove(false);
                  setIndex("");
                  setData("");
                }}
              >
                Cancel
              </button>
              <button
                className="text-sm px-5 rounded py-2 bg-red-600 hover:bg-red-700 border"
                onClick={() => {
                  handleRemovePlayer();
                }}
              >
                {loading ? "Removing.." : "Remove"}
              </button>
            </div>
          </div>
        </div>
      )}
      {playerForm ? (
        <AddPlayerForm
          handleCloseForm={closePlayerForm}
          info={{ events, tournament, entry }}
        />
      ) : (
        <div className="relative overflow-x-auto ">
          <div className="my-5 flex justify-between ">
            <div className="text-sm text-gray-500">
              {entries?.length} players found
            </div>
            <div onClick={exportPlayers}>
              <button className="px-3 py-2 rounded bg-blue-700 hover:bg-blue-600 hover:shadow text-white font-medium text-sm">
                {exportPlayer ? "loading.." : "Export Players"}
              </button>
            </div>
          </div>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 shadow-md sm:rounded-lg">
            <thead className="text-xs text-gray-700 uppercase bg-gray-200">
              <tr>
                <th scope="col" className="px-2 py-3 w-[4%]">
                  Edit
                </th>
                <th scope="col" className="px-3 py-3 w-[7%]">
                  Checkin
                </th>
                <th scope="col" className="px-3 py-3 w-[20%]">
                  Player
                </th>
                <th scope="col" className="px-2 py-3 w-[8%]">
                  Member ID
                </th>
                <th scope="col" className="px-3 py-3 w-[13%]">
                  Events
                </th>
                <th scope="col" className="px-2 py-3 w-[5%]">
                  Total
                </th>
                <th scope="col" className="px-2 py-3 w-[5%]">
                  Paid
                </th>
                <th scope="col" className="px-2 py-3 w-[5%]">
                  Remaining
                </th>
                <th scope="col" className="px-3 py-3 w-[15%]">
                  Status
                </th>
                <th scope="col" className="px-3 py-3 w-[15%]">
                  Note
                </th>
                <th scope="col" className="px-2 py-3 w-[3%]">
                  Remove
                </th>
              </tr>
            </thead>
            <tbody>
              {entries?.map((entry, i) => (
                <tr
                  key={i}
                  className="odd:bg-gray-50  even:bg-gray-100 border-b"
                >
                  <td className="px-2  justify-center py-4">
                    <button
                      onClick={() => openPlayerForm(entry)}
                      className="mt-1"
                    >
                      <SquaresPlusIcon height={20} width={20} />
                    </button>
                  </td>
                  <td
                    onClick={() => {
                      setCheckin(true);
                      setIndex(i);
                    }}
                    className="px-3 py-4"
                  >
                    <div className="text-[10px]">
                      {entry?.checkins.filter((c) => c.checkin === true).length}{" "}
                      checkins
                    </div>

                    <label
                      onClick={(e) => e.stopPropagation()}
                      className="inline-flex items-center cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        checked={entry?.checkins[0]?.checkin || false} // Default to false if undefined
                        onChange={() =>
                          handleToggleChange(
                            entry,
                            format(dates[0], "yyyy-MM-dd")
                          )
                        }
                        className="sr-only peer"
                      />
                      <div className="relative w-10 h-4 bg-gray-300 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[0px] after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                    {checkin && index === i && (
                      <div
                        className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay"
                        onClick={(e) => {
                          setCheckin(false);
                          setIndex("");
                          e.stopPropagation();
                        }}
                      >
                        <div
                          className="px-3 bg-white rounded-lg"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="flex gap-16 justify-between p-1 ">
                            <div className="text-xl font-bold text-black">
                              Player Checkin
                            </div>
                            <button
                              onClick={() => {
                                setCheckin(false);
                                setIndex("");
                              }}
                              className="px-3 text-white text-lg font-medium rounded-sm bg-blue-800 hover:bg-blue-900"
                            >
                              x
                            </button>
                          </div>
                          <div className="p-5 space-y-3">
                            {dates?.map((date, i) => {
                              const isCheckedIn = entry?.checkins.some(
                                (checkin) =>
                                  checkin.date === format(date, "yyyy-MM-dd") &&
                                  checkin.checkin === true
                              );

                              return (
                                <div key={i} className="flex gap-5">
                                  <div className="text-gray-700">
                                    {format(date, "EEE MMM dd yyyy")}
                                  </div>
                                  <label className="inline-flex items-center cursor-pointer">
                                    <input
                                      type="checkbox"
                                      checked={isCheckedIn}
                                      onChange={() =>
                                        handleToggleChange(
                                          entry,
                                          format(date, "yyyy-MM-dd")
                                        )
                                      }
                                      className="sr-only peer"
                                    />
                                    <div className="relative w-10 h-4 bg-gray-300 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[0px] after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                  </label>
                                  <div className="text-[8px] leading-3">
                                    {entry?.checkins.find(
                                      (checkin) =>
                                        checkin.date ===
                                        format(date, "yyyy-MM-dd")
                                    )?.time || ""}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </td>
                  <th
                    scope="row"
                    className="px-1 py-1 font-medium text-gray-900 whitespace-nowrap "
                  >
                    <div className="bg-white rounded-xl px-3">
                      <div className="flex gap-3">
                        <div className="text-[10px] text-gray-500">
                          {entry?.player?.rating}
                        </div>
                        {entry?.player?.club && (
                          <div className="text-[10px] text-gray-500 font-medium">
                            {entry?.player?.club?.clubName},{" "}
                            {entry?.player?.club?.region},{" "}
                            {entry?.player?.club?.state}
                          </div>
                        )}
                      </div>

                      <div className="text-lg">
                        {entry?.player?.lastName}, {entry?.player?.firstName}
                      </div>
                    </div>
                  </th>
                  <td className="px-2 py-4">
                    <div
                      className={`text-xs px-1 ${
                        entry?.player?.memberId
                          ? "bg-blue-500 text-white"
                          : "bg-yellow-200"
                      } text-center`}
                    >
                      {entry?.player?.memberId
                        ? entry?.player?.memberId
                        : entry?.player?.tempId}
                    </div>
                  </td>
                  <td className="px-2 py-1">
                    {" "}
                    <div className="flex flex-wrap gap-1">
                      {entry?.events?.map((event, i) => (
                        <div
                          key={i}
                          className="text-xs bg-gray-300 rounded-sm px-3"
                        >
                          {event?.eventName}
                        </div>
                      ))}
                    </div>
                  </td>
                  <td className="px-2 py-4">
                    <div className="font-medium">
                      $
                      {(
                        (Number(entry?.tournamentFee) || 0) +
                        entry?.events?.reduce(
                          (sum, event) => sum + (Number(event?.eventFee) || 0),
                          0
                        ) +
                        entry?.charges?.reduce(
                          (sum, charge) => sum + (Number(charge?.charge) || 0),
                          0
                        ) +
                        (Number(entry?.membershipFee) || 0)
                      ).toFixed(2)}
                    </div>
                  </td>
                  <td className="px-2 py-4">
                    <div className="font-medium">
                      $
                      {entry?.payments?.reduce(
                        (sum, payment) => sum + (Number(payment?.payment) || 0),
                        0
                      ) +
                        entry?.discounts?.reduce(
                          (sum, discount) =>
                            sum + (Number(discount?.discount) || 0),
                          0
                        )}
                    </div>
                  </td>
                  <td className="px-2 py-4">
                    <div className="font-medium text-red-600">
                      $
                      {(
                        (Number(entry?.tournamentFee) || 0) +
                        entry?.events?.reduce(
                          (sum, event) => sum + (Number(event?.eventFee) || 0),
                          0
                        ) +
                        entry?.charges?.reduce(
                          (sum, charge) => sum + (Number(charge?.charge) || 0),
                          0
                        ) +
                        (Number(entry?.membershipFee) || 0) -
                        entry?.payments?.reduce(
                          (sum, payment) =>
                            sum + (Number(payment?.payment) || 0),
                          0
                        ) -
                        entry?.discounts?.reduce(
                          (sum, discount) =>
                            sum + (Number(discount?.discount) || 0),
                          0
                        )
                      ).toFixed(2)}
                    </div>
                  </td>
                  <td className="px-2 py-1 text-[9px] whitespace-nowrap space-y-[2px]">
                    {entry?.notes?.selfRegistered && (
                      <div className="bg-gray-400 text-center self-center text-white  rounded-sm px-1">
                        SELF REGISTERED
                      </div>
                    )}
                    {entry?.notes?.newMember && (
                      <div className="bg-yellow-400 text-center self-center text-white  rounded-sm px-1">
                        NEW MEMBER
                      </div>
                    )}
                    {entry?.notes?.membershipSold && (
                      <div className="bg-blue-400 text-center self-center text-white  rounded-sm px-1">
                        MEMBERSHIP SOLD
                      </div>
                    )}
                    {entry?.notes?.membershipExpired && (
                      <div className="bg-red-400 text-center self-center text-white  rounded-sm px-1">
                        MEMBERSHIP EXPIRED
                      </div>
                    )}
                    {entry?.notes?.noMembership && (
                      <div className="bg-orange-400 text-center self-center text-white  rounded-sm px-1">
                        NO MEMBERSHIP
                      </div>
                    )}
                  </td>
                  <td className="px-2 py-1 text-xs whitespace-nowrap">
                    <input
                      type="text"
                      maxLength={200}
                      value={notes[entry.id] || entry?.noteText || ""}
                      onChange={(e) =>
                        setNotes((prev) => ({
                          ...prev,
                          [entry.id]: e.target.value,
                        }))
                      }
                      className="bg-gray-100 py-0.5 border border-gray-200 rounded w-full"
                      placeholder="Entry Note"
                    />
                  </td>

                  <td className="px-3 py-1 text-xs">
                    <button
                      disabled={loading}
                      onClick={() => {
                        if (tournamentLevel && entry?.events?.length > 0) {
                          toast.warn("Remove Player from Event first");
                          return;
                        } else {
                          setData({
                            eventId,
                            data: {
                              playerId: entry.player.id,
                              entryId: entry.id,
                            },
                          });
                          setRemove(true);
                          setIndex(i);
                        }
                      }}
                      className="hover:scale-110"
                      title="Remove Player"
                    >
                      {loading && index === i ? (
                        <div className="flex items-center justify-center">
                          <svg
                            className="animate-spin h-5 w-5 text-gray-100"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                            ></path>
                          </svg>
                        </div>
                      ) : (
                        <ArchiveBoxXMarkIcon height={20} width={20} />
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default AdminPlayersTab;
