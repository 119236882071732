import React from "react";

const FormFieldError = ({ errors, field }) => {
  return (
    <>
      {errors && errors[field] && (
        <p className="text-xs font-medium text-red-700 capitalize">
          {errors[field]?.message}
        </p>
      )}
    </>
  );
};

export default FormFieldError;
