import React from "react";
import { Outlet } from "react-router-dom";
import { Header } from "../components";
import BreadCrumb from "../components/commons/BreadCrumb";

export default function Layout() {
  return (
    <div className="min-h-screen bg-gray-50">
      <Header />

      <main className="py-10 ">
        <div className="container mx-auto">
          <BreadCrumb />
          <Outlet />
        </div>
      </main>
    </div>
  );
}
