import React, { useEffect, useState } from "react";
import AddTournamentForm from "./AddTournamnetForm";
import {
  useDuplicateTournamentMutation,
  useGetAllTournamentsQuery,
} from "../../../redux/features/api/apiSlice";
import { useSelector } from "react-redux";
import {
  Squares2X2Icon,
  Bars2Icon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import { TournamentCard } from "../../../components";
import loading from "../../../images/loading-image.svg";
import { toast } from "react-toastify";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";

const TournamentsPage = () => {
  const [activeTab, setActiveTab] = useState("active");
  const [search, setSearch] = useState("");
  const { currentUser } = useSelector((state) => state.user);
  const { isFetching, refetch } = useGetAllTournamentsQuery(
    new URLSearchParams({
      id: currentUser.id,
      email: currentUser.email,
      activeTab: activeTab,
      search: search,
    }).toString()
  );
  const [showForm, setShowForm] = useState(false);
  const { tournaments } = useSelector((state) => state.tournaments);
  const [duplicateTournament, {isLoading: duplicating}] = useDuplicateTournamentMutation();

  const handleDuplicate = async (tournament) => {
    try {
      const res = await duplicateTournament(tournament);
      if (!res.data.error) {
        toast.success("Duplicate Tournament Created");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const [dataView, setDataView] = useState("grid"); //! grid | tabel

  const handleTabChange = () => {
    setActiveTab(activeTab === "active" ? "past" : "active");
  };
  const handleCloseForm = () => {
    setShowForm(false);
  };

  useEffect(() => {
    refetch();
  }, [activeTab, search]);

  return (
    <>
      {showForm ? (
        <AddTournamentForm handleCloseForm={handleCloseForm} />
      ) : (
        <div className="container mx-auto px-3">
          <div className="flex flex-row items-center justify-end">
            <button
              onClick={() => setShowForm(true)}
              className="inline-flex items-center px-3 py-2 text-xs sm:text-sm border-2 border-blue-900 font-semibold text-blue-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Add New Tournament
            </button>
          </div>

          {/* toolbar */}
          <div className="flex flex-row items-center justify-between py-6">
            {/* tabs */}
            <div className="flex flex-row items-center justify-start py-4 ">
              <div className="divide-x-2 text-[10px] sm:text-sm">
                <button
                  onClick={handleTabChange}
                  className={`px-2 sm:px-4 py-2  uppercase rounded-s-md ${
                    activeTab === "active"
                      ? "bg-blue-900 text-white font-medium"
                      : "bg-white text-black"
                  }`}
                >
                  active
                </button>
                <button
                  onClick={handleTabChange}
                  className={`px-2 sm:px-4 py-2  uppercase rounded-e-md ${
                    activeTab === "past"
                      ? "bg-blue-900 text-white font-medium"
                      : "bg-white text-black"
                  }`}
                >
                  past
                </button>
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-2 py-4">
              {/* Search bar */}
              <div className="flex flex-row items-center justify-start px-1 sm:px-4 py-1 sm:py-2 bg-white border rounded-md ">
                <MagnifyingGlassIcon className="h-4 w-4 sm:w-5 sm:h-5" />
                <input
                  type="search"
                  onChange={(e) => setSearch(e.target.value)}
                  className="px-1 bg-transparent border-none outline-none focus:outline-none"
                />
              </div>

              {/* data view */}
              <div className="hidden sm:block">
                <button
                  onClick={() =>
                    setDataView(dataView === "grid" ? "tabel" : "grid")
                  }
                  className={`px-2 sm:px-4 py-1 sm:py-2  uppercase rounded-s-md ${
                    dataView === "grid"
                      ? "bg-blue-900 text-white font-medium"
                      : "bg-white text-black"
                  }`}
                >
                  <Squares2X2Icon className="h-3 w-3 sm:w-5 sm:h-5" />
                </button>
                <button
                  onClick={() =>
                    setDataView(dataView === "tabel" ? "grid" : "tabel")
                  }
                  className={`px-2 sm:px-4 py-1 sm:py-2  uppercase rounded-e-md ${
                    dataView === "tabel"
                      ? "bg-blue-900 text-white font-medium"
                      : "bg-white text-black"
                  }`}
                >
                  <Bars2Icon className="h-3 w-3 sm:w-5 sm:h-5 " />
                </button>
              </div>
            </div>
          </div>

          {isFetching ? (
            <div className=" h-[500px] w-full flex justify-center items-center">
              <img className="-mt-32" src={loading} alt="" />
            </div>
          ) : tournaments?.length < 1 ? (
            <div className="text-center mt-20 font-medium text-gray-500">
              No Tournaments Available
            </div>
          ) : (
            <div
              className={`grid gap-4  ${
                activeTab === "grid" ? "grid-cols-4" : "grid-cols-12"
              }`}
            >
              {tournaments?.map((tournament, index) => {
                return (
                  <div
                    key={index}
                    className={`px-6 py-4 text-white bg-gradient-to-r from-cyan-900  to-blue-800 border border-gray-300 rounded-md shadow-sm ${
                      dataView === "grid"
                        ? "col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3"
                        : "col-span-12"
                    }`}
                  >
                    <div className="flex justify-end -mb-1">
                      {duplicating ? (
                        <div className="text-xs text-gray-500">loading..</div>
                      ) : (
                        <button
                          onClick={() => handleDuplicate(tournament)}
                          className=""
                          title="Duplicate Tournament"
                        >
                          <DocumentDuplicateIcon height={20} width={20} />
                        </button>
                      )}
                    </div>
                    <TournamentCard tournament={tournament} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TournamentsPage;
