import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    credentials: "include",
    // prepareHeaders: (headers) => {
    //   headers.set("Content-Type", "application/json");
    //   return headers;
    // },
    // responseHandler: async (response) => {
    //   const rawResponse = await response.text();
    //   console.log("Raw Response:", rawResponse); // Log raw response
    //   return response.json();
    // },
  }),
  tagTypes: [
    "new-tournament",
    "new-event",
    "new-player",
    "new-draw",
    "event-players",
    "entry",
  ],
  endpoints: (builder) => ({
    // auth builders

    login: builder.mutation({
      query: (data) => ({
        url: "/auth/login",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-user"],
    }),
    logout: builder.mutation({
      query: (data) => ({
        url: "/auth/logout",
        method: "POST",
      }),
      invalidatesTags: ["new-user"],
    }),
    getUser: builder.query({
      query: (id) => `/auth/user/${id}`,
      providesTags: ["new-user"],
    }),
    signup: builder.mutation({
      query: (data) => ({
        url: "/auth/signup",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-user"],
    }),
    createProfile: builder.mutation({
      query: (data) => ({
        url: "/auth/profile",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-user"],
    }),
    connectProfile: builder.mutation({
      query: (data) => ({
        url: "/auth/connectProfile",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-user"],
    }),
    updateProfile: builder.mutation({
      query: (data) => ({
        url: "/auth/updateProfile",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-user"],
    }),

    // tournament builders
    getAllTournaments: builder.query({
      query: (queryString) => `/tournament/admin?${queryString}`,
      providesTags: ["new-tournament"],
    }),
    getQueryTournaments: builder.query({
      query: (query) => `/tournament/query?${query}`,
      providesTags: ["new-tournament"],
    }),
    getTournamentById: builder.query({
      query: (id) => `/tournament/${id}`,
      providesTags: ["new-tournament", "new-event"],
    }),
    addNewTournament: builder.mutation({
      query: (data) => ({
        url: "/tournament",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-tournament"],
    }),
    duplicateTournament: builder.mutation({
      query: (data) => ({
        url: "/tournament/duplicate",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-tournament"],
    }),
    updateTournament: builder.mutation({
      query: ({ id, data }) => ({
        url: `/tournament/update/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["new-tournament"],
    }),

    // events builders

    getAllEvents: builder.query({
      query: () => `/event`,
      providesTags: ["new-event"],
    }),
    getEventById: builder.query({
      query: (id) => `/event/${id}`,
      providesTags: ["new-event"],
    }),
    getEventsByTournamentId: builder.query({
      query: (tournamentId) => `/event/tournament/${tournamentId}`,
      providesTags: ["new-event"],
    }),
    getQueryEventsByTournamentId: builder.query({
      query: ({ tournamentId, query }) =>
        `/event/query/${tournamentId}?status=${query}`,
      providesTags: ["new-event"],
    }),

    addNewEvent: builder.mutation({
      query: (data) => ({
        url: `/event`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-event"],
    }),
    updateEvent: builder.mutation({
      query: ({ id, data }) => ({
        url: `/event/update/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["new-event"],
    }),
    openCloseEvent: builder.mutation({
      query: ({ id, data }) => ({
        url: `/event/openClose/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["new-event"],
    }),
    eventEnrollment: builder.mutation({
      query: ({ eventId, data }) => ({
        url: `/event/enrollment/${eventId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        "event-players",
        "entry",
        "eligible-players",
        "unassigned",
      ],
    }),
    eventRemovePlayer: builder.mutation({
      query: ({ id, data }) => ({
        url: `/event/removePlayer/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        "event-players",
        "entry",
        "eligible-players",
        "unassigned",
      ],
    }),

    // Players builders

    getAllPlayer: builder.query({
      query: () => `/players`,
      providesTags: ["new-player"],
    }),
    getPlayerById: builder.query({
      query: (id) => `/players/${id}`,
    }),
    getPlayersByTournamentId: builder.query({
      query: (tournamentId) => `/players/tournament/${tournamentId}`,
      providesTags: ["new-player"],
    }),
    getPlayersByEventId: builder.query({
      query: (eventId) => `/event/players/${eventId}`,
      providesTags: ["event-players"],
    }),
    getEventEligiblePlayers: builder.query({
      query: (queryString) => `/players/eventEligible?${queryString}`,
      providesTags: ["eligible-players"],
    }),
    searchPlayer: builder.query({
      query: ({ searchTerm, tournamentId }) =>
        `/players/searchPlayer?searchTerm=${searchTerm}&tournamentId=${tournamentId}`,
    }),
    searchById: builder.query({
      query: ({ searchTerm }) =>
        `/players/searchPlayer?searchTerm=${searchTerm}`,
    }),
    addNewPlayer: builder.mutation({
      query: (data) => ({
        url: "/players/add",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-player", "entry"],
    }),
    updatePlayer: builder.mutation({
      query: ({ data, id }) => ({
        url: `/players/update/${id}`,
        method: "POST",
        body: data, // Passing the correct form data
      }),
      invalidatesTags: ["new-player", "entry"],
    }),
    selfRegistration: builder.mutation({
      query: ({ data, id }) => ({
        url: `/players/selfRegistration/${id}`,
        method: "POST",
        body: data, // Passing the correct form data
      }),
      invalidatesTags: ["new-player", "entry"],
    }),
    getPlayerEntries: builder.query({
      query: (id) => `/players/entries/${id}`,
      providesTags: ["entry"],
    }),

    importPlayer: builder.mutation({
      query: (data) => ({
        url: `/players/import`,
        method: "POST",
        body: data, // Pass FormData directly
      }),
      invalidatesTags: ["new-player"],
    }),

    // tables builders
    getTableById: builder.query({
      query: (id) => `/table/${id}`,
    }),
    getTournamentTables: builder.query({
      query: (id) => `/table/tournament/${id}`,
      providesTags: ["new-table"],
    }),
    getTableMatches: builder.query({
      query: ({ id, queryString }) => `/table/match/${id}?${queryString}`,
      providesTags: ["match"],
    }),
    addNewTable: builder.mutation({
      query: ({ id, data }) => ({
        url: `/table/tournament/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-table"],
    }),
    deleteTable: builder.mutation({
      query: (id) => ({
        url: `/table/delete/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["new-table"],
    }),

    // draw builders
    getEventDraw: builder.query({
      query: (id) => `/draw/event/${id}`,
      providesTags: ["draw"],
    }),
    getEventDrawUser: builder.query({
      query: (id) => `/draw/event/user/${id}`,
      providesTags: ["draw"],
    }),
    getUnAssignedPlayers: builder.query({
      query: (id) => `/draw/unassigned/${id}`,
      providesTags: ["unassigned"],
    }),
    displayDraw: builder.query({
      query: (query) => `/draw/display?${query}`,
      providesTags: ["draw"],
    }),
    addNewDraw: builder.mutation({
      query: (data) => ({
        url: "/draw/new",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["draw"],
    }),
    updateDraw: builder.mutation({
      query: ({ id, data }) => ({
        url: `/draw/update/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["draw"],
    }),
    deleteDraw: builder.mutation({
      query: (id) => ({
        url: `/draw/delete/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["draw", "new-event"],
    }),
    resetDraw: builder.mutation({
      query: (id) => ({
        url: `/draw/reset/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["draw"],
    }),
    finalDraw: builder.mutation({
      query: ({ id, data }) => ({
        url: `/draw/final/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["draw"],
    }),
    drawSeeding: builder.mutation({
      query: ({ id, data }) => ({
        url: `/draw/seeding/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["draw"],
    }),
    activateKnockout: builder.mutation({
      query: ({ id, data }) => ({
        url: `/draw/activateKnockout/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["draw", "match"],
    }),

    //matches
    getGroupMatches: builder.query({
      query: (id) => `/match/group/${id}`,
      providesTags: ["match"],
    }),
    getPlayerMatches: builder.query({
      query: (queryString) => `/match/player?${queryString}`,
      providesTags: ["match"],
    }),
    getEventMatches: builder.query({
      query: (queryString) => `/match/event?${queryString}`,
      providesTags: ["match"],
    }),
    getMatchByTournamentId: builder.query({
      query: (queryString) => `/match/tournament?${queryString}`,
      providesTags: ["match"],
    }),
    getExportMatches: builder.query({
      query: (id) => `/match/export/${id}`,
    }),
    getTournamentMatches: builder.query({
      query: (id) => `/match/tournament/${id}`,
      providesTags: ["match"],
    }),
    matchTables: builder.mutation({
      query: ({ id, data }) => ({
        url: `/match/table/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["draw", "match"],
    }),
    matchResult: builder.mutation({
      query: ({ id, data }) => ({
        url: `/match/result/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["draw", "match"],
    }),

    //group
    getDrawGroups: builder.query({
      query: (id) => `/group/draw/${id}`,
      providesTags: ["match"],
    }),
    groupTables: builder.mutation({
      query: ({ id, data }) => ({
        url: `/group/tables/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["draw", "match"],
    }),
    addNewPlayerSE: builder.mutation({
      query: ({ id, data }) => ({
        url: `/group/newPlayerSE/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["event-players", "entry", "unassigned", "draw"],
    }),
    addNewPlayerRR: builder.mutation({
      query: ({ id, data }) => ({
        url: `/group/newPlayerRR/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        "event-players",
        "entry",
        "unassigned",
        "draw",
        "match",
      ],
    }),

    // entry builders
    getTournamentFinances: builder.query({
      query: (id) => `/entry/tournamentFinances/${id}`,
      providesTags: ["entry"],
    }),
    getEventEntries: builder.query({
      query: (id) => `/entry/eventEntries/${id}`,
      providesTags: ["entry"],
    }),
    getEventEntriesUser: builder.query({
      query: (id) => `/entry/eventEntries/user/${id}`,
      providesTags: ["entry"],
    }),
    getTournamentEntries: builder.query({
      query: (id) => `/entry/tournament/${id}`,
      providesTags: ["entry"],
    }),
    getTournamentEntriesUser: builder.query({
      query: (id) => `/entry/tournament/user/${id}`,
      providesTags: ["entry"],
    }),
    entryCheckin: builder.mutation({
      query: (data) => ({
        url: `/checkin/add`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["entry"],
    }),
    entryNote: builder.mutation({
      query: ({ id, data }) => ({
        url: `/entry/note/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["entry"],
    }),

    //clubs
    getAllClubs: builder.query({
      query: (query) => `/clubs/all/user${query}`,
      providesTags: ["clubs"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useLoginMutation,
  useLogoutMutation,
  useSignupMutation,
  useCreateProfileMutation,
  useConnectProfileMutation,
  useUpdateProfileMutation,
  useGetAllTournamentsQuery,
  useGetQueryTournamentsQuery,
  useAddNewTournamentMutation,
  useDuplicateTournamentMutation,
  useUpdateTournamentMutation,
  useGetTournamentByIdQuery,
  useGetAllEventsQuery,
  useGetQueryEventsByTournamentIdQuery,
  useLazyGetQueryEventsByTournamentIdQuery,
  useLazyGetEventsByTournamentIdQuery,
  useAddNewEventMutation,
  useUpdateEventMutation,
  useOpenCloseEventMutation,
  useGetEventByIdQuery,
  useGetPlayersByEventIdQuery,
  useGetEventEligiblePlayersQuery,
  useLazyGetUnAssignedPlayersQuery,
  useGetEventsByTournamentIdQuery,
  useGetAllPlayerQuery,
  useAddNewPlayerMutation,
  useUpdatePlayerMutation,
  useSelfRegistrationMutation,
  useGetPlayerByIdQuery,
  useGetPlayerEntriesQuery,
  useGetPlayersByTournamentIdQuery,
  useSearchPlayerQuery,
  useSearchByIdQuery,
  useGetTableByIdQuery,
  useGetTableMatchesQuery,
  useGetTournamentTablesQuery,
  useAddNewTableMutation,
  useDeleteTableMutation,
  useAddNewDrawMutation,
  useDisplayDrawQuery,
  useUpdateDrawMutation,
  useDeleteDrawMutation,
  useResetDrawMutation,
  useFinalDrawMutation,
  useDrawSeedingMutation,
  useActivateKnockoutMutation,
  useGroupTablesMutation,
  useGetEventDrawQuery,
  useLazyGetEventDrawUserQuery,
  useImportPlayerMutation,
  useEventEnrollmentMutation,
  useEventRemovePlayerMutation,
  useAddNewPlayerRRMutation,
  useAddNewPlayerSEMutation,
  useGetTournamentFinancesQuery,
  useGetEventEntriesQuery,
  useLazyGetEventEntriesQuery,
  useGetTournamentEntriesQuery,
  useLazyGetTournamentEntriesQuery,
  useGetTournamentEntriesUserQuery,
  useLazyGetEventEntriesUserQuery,
  useEntryCheckinMutation,
  useEntryNoteMutation,
  useGetGroupMatchesQuery,
  useGetPlayerMatchesQuery,
  useGetEventMatchesQuery,
  useGetDrawGroupsQuery,
  useMatchTablesMutation,
  useMatchResultMutation,
  useGetExportMatchesQuery,
  useGetMatchByTournamentIdQuery,
  useGetAllClubsQuery,
  useLazyGetAllClubsQuery,
} = apiSlice;
