import React from "react";
import { useParams } from "react-router-dom";
import { useGetTournamentFinancesQuery } from "../../../redux/features/api/apiSlice";
import { useSelector } from "react-redux";
import loadingImg from "../../../images/loading-image.svg";

const TournamentFinanceTab = () => {
  let { tournamentId } = useParams();
  const { isFetching } = useGetTournamentFinancesQuery(tournamentId);
  const { finances } = useSelector((state) => state.entries);

  let amount = finances.totals;
  let entries = finances.eventEntries;

  return (
    <>
      {isFetching && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay">
          <div className="  relative mx-3">
            <img className="" src={loadingImg} alt="" />
          </div>
        </div>
      )}
      <div className="relative overflow-x-auto  sm:rounded-lg mt-5">
        <table className="w-full text-sm text-left rtl:text-right shadow text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-200">
            <tr>
              <th scope="col" className="px-6 py-3 w-[18%]"></th>
              <th scope="col" className="px-6 py-3 w-[12%]">
                Player Fees
              </th>
              <th scope="col" className="px-6 py-3 w-[15%]">
                Custom Charges
              </th>
              <th scope="col" className="px-6 py-3 w-[15%]">
                Custiom Discounts
              </th>
              <th scope="col" className="px-6 py-3 w-[10%]">
                Total Due
              </th>
              <th scope="col" className="px-6 py-3 w-[10%]">
                Total Paid
              </th>
              <th scope="col" className="px-6 py-3 w-[15%]">
                Remaining Balance
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="odd:bg-white font-semibold even:bg-gray-50 border-b">
              <td className="px-6 py-4">
                <div>All Registered Players</div>
              </td>
              <td className="px-6 py-1  text-gray-900 whitespace-nowrap">
                ${amount?.total || 0}
              </td>
              <td className="px-6 py-4">${amount?.charge || 0}</td>
              <td className="px-6 py-4">${amount?.discount || 0}</td>
              <td className="px-6 py-4 text-black">
                ${amount?.total + amount?.charge - amount?.discount || 0}
              </td>
              <td className="px-6 py-4 text-black">
                ${amount?.amountPaid || 0}
              </td>
              <td className="px-6 py-4 text-black">
                ${amount?.remaining || 0}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="mt-20 ">
          <table className="w-full text-sm text-left rtl:text-right shadow-md text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-200">
              <tr>
                <th scope="col" className="px-6 py-3">
                  <div>Individual Events</div>
                </th>
                <th scope="col" className="px-6 py-3">
                  <div>Total Players</div>
                </th>
                <th scope="col" className="px-6 py-3">
                  Total Event Fee
                </th>
                <th scope="col" className="px-6 py-3">
                  Custom Charges
                </th>
                <th scope="col" className="px-6 py-3">
                  Custom Discounts
                </th>
                <th scope="col" className="px-6 py-3">
                  Total Due
                </th>
                <th scope="col" className="px-6 py-3">
                  Total Paid
                </th>
              </tr>
            </thead>
            <tbody>
              {entries &&
                entries.map((event, i) => (
                  <tr
                    key={i}
                    className="odd:bg-white  even:bg-gray-50 border-b"
                  >
                    <td className="px-6 py-1 text-gray-900 whitespace-nowrap font-semibold">
                      {event.name}
                    </td>
                    <td className="px-6 py-4">{event?.totalPlayers || 0}</td>
                    <td className="px-6 py-4">${event?.totalEventFee || 0}</td>
                    <td className="px-6 py-4">${event?.totalCharge || 0}</td>
                    <td className="px-6 py-4">${event?.totalDiscount || 0}</td>
                    <td className="px-6 py-4 text-black">
                      $
                      {event?.totalEventFee +
                        event?.totalCharge -
                        event?.totalDiscount || 0}
                    </td>
                    <td className="px-6 py-4 text-black">
                      ${event?.totalPayment || 0}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TournamentFinanceTab;
