import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useActivateKnockoutMutation,
  useAddNewPlayerRRMutation,
  useAddNewPlayerSEMutation,
  useFinalDrawMutation,
  useGetEventDrawQuery,
  useLazyGetUnAssignedPlayersQuery,
  useResetDrawMutation,
} from "../../../redux/features/api/apiSlice";
import loadingImg from "../../../images/loading-image.svg";
import { useSelector } from "react-redux";
import { ChevronDownIcon, SquaresPlusIcon } from "@heroicons/react/16/solid";
import AddDrawForm from "../../../pages/admin/Events/AddDrawForm";
import { toast } from "react-toastify";
import GroupCard from "../../card/Group";
import RoundRobin from "../draws/RoundRobin";
import SingleElimination from "../draws/SingleElimination";
import FinalizedSingleElimination from "../draws/FinalizedSingleElimination";

const DrawsTab = ({ event, tournament }) => {
  let { tournamentId, eventId } = useParams();
  const [finalDraw, { isLoading: finalizing }] = useFinalDrawMutation();
  const [newPlayerRR, { isLoading: addingPlayerRR }] =
    useAddNewPlayerRRMutation();
  const [newPlayerSE, { isLoading: addingPlayerSE }] =
    useAddNewPlayerSEMutation();
  const [activateKnockout] = useActivateKnockoutMutation();

  const [resetDraw] = useResetDrawMutation();
  const { isFetching, refetch } = useGetEventDrawQuery(eventId);
  const { draw } = useSelector((state) => state.draws);
  const [fetchUnAssigned] = useLazyGetUnAssignedPlayersQuery(); // Rename for clarity
  const { unassigned } = useSelector((state) => state.draws);
  const [drawForm, setDrawForm] = useState(false);
  const [deleteDraw, setDelteDraw] = useState(false);
  const [addPlayer, setAddPlayer] = useState(false);
  const [index, setIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [seeding, setSeeding] = useState(true);
  const [stage, setStage] = useState(
    draw?.roundRobinSettings?.activateSE || false
  );
  const [confirmActivate, setConfirmActivate] = useState(false);
  const [confirmFinalize, setConfirmFinalize] = useState(false);

  useEffect(() => {
    if (!isFetching && draw) {
      fetchUnAssigned(eventId);
    }
  }, [isFetching, draw]); // Only run when `isFetching` changes to `false` or `draw` is updated

  const handleDrawForm = () => {
    setDrawForm(false);
  };

  const handleDeleteDraw = () => {
    setDelteDraw(!deleteDraw);
  };

  const checkClubConflictRR = () => {
    for (const group of draw.groups) {
      const clubCount = {};

      for (const groupPlayer of group.groupPlayers) {
        const clubId = groupPlayer.player?.club?.id;
        const clubName = groupPlayer.player?.club?.clubName;

        if (clubId) {
          clubCount[clubId] = (clubCount[clubId] || 0) + 1;

          if (clubCount[clubId] > 1) {
            return `Group ${
              group.number + 1
            } has 2 or more players from the same club (Club Name: ${clubName}).`;
          }
        }
      }
    }
    return null;
  };

  const checkClubConflictSE = () => {
    const sortedGroups = [...draw?.groups].sort((a, b) => a.number - b.number);

    const sortedPlayers = [...sortedGroups[0].groupPlayers].sort(
      (a, b) => a.order - b.order
    );

    for (let i = 0; i < sortedPlayers.length; i += 2) {
      const player1 = sortedPlayers[i];
      const player2 = sortedPlayers[i + 1];

      if (player1 && player2) {
        const clubId1 = player1?.player?.club?.id;
        const clubId2 = player2?.player?.club?.id;

        if (clubId1 && clubId2 && clubId1 === clubId2) {
          const clubName = player1?.player?.club?.clubName; // Both players belong to the same club
          return `Match ${Math.ceil(
            (i + 1) / 2
          )} has players from the same club (Club Name: ${clubName}).`;
        }
      }
    }
    return null; // No conflicts found
  };

  const finalizeDraw = async () => {
    try {
      const res = await finalDraw({
        id: draw.id,
        data: { finalized: !draw.finalized, tournamentId, eventId },
      });
      if (res?.data?.error === false) {
        toast.success(res.data.data);
        window.location.reload();
      }
      if (res?.error?.data?.error !== undefined) {
        toast.warning(res.error.data.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setConfirmFinalize();
    }
  };

  const handleNewPlayerRR = async (id, data) => {
    try {
      const res = await newPlayerRR({
        id,
        data,
      });
      if (res?.data?.error === false) {
        toast.success(res.data.data);
      }
      if (res?.error?.data?.error !== undefined) {
        toast.warning(res.error.data.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIndex(null);
      setAddPlayer(false);
    }
  };

  // const addingClubConflictSE = (data) => {
  //   const sortedGroups = [...draw?.groups].sort((a, b) => a.number - b.number);

  //   const sortedPlayers = [...sortedGroups[0].groupPlayers].sort(
  //     (a, b) => a.order - b.order
  //   );

  //     const player1 =  data.player;
  //     const player2 = sortedPlayers[data.place.order % 2 === 0 ? data.place.order+1 : data.place.order - 1 ];

  //     if (player1 && player2) {
  //       const clubId1 = player1?.club?.id;
  //       const clubId2 = player2?.player?.club?.id;
  //       if (clubId1 && clubId2 && clubId1 === clubId2) {
  //         const clubName = player1?.club?.clubName; // Both players belong to the same club
  //         return `Player ${player2.order +1} has players from the same club (Club Name: ${clubName}).`;
  //       }
  //     }

  //   return null; // No conflicts found
  // };

  const handleNewPlayerSE = async (id, data) => {
    try {
      const res = await newPlayerSE({
        id,
        data,
      });
      if (res?.data?.error === false) {
        toast.success(res.data.data);
      }
      if (res?.error?.data?.error !== undefined) {
        toast.warning(res.error.data.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIndex(null);
      setAddPlayer(false);
    }
  };

  const handleActivateKnockout = async () => {
    if (loading) return;
    setLoading(true);

    try {
      let roundRobinSettings = { ...draw?.roundRobinSettings }; // Create a copy of roundRobinSettings
      roundRobinSettings.activateSE = !roundRobinSettings.activateSE; // Toggle activateSE

      const res = await activateKnockout({
        id: draw.id,
        data: { roundRobinSettings, tournamentId }, // Send updated roundRobinSettings
      });

      if (res?.data?.error === false) {
        toast.success(res.data.data);
      } else if (res?.error?.data?.error) {
        toast.error(res.error.data.error);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
      setConfirmActivate(false);
    }
  };

  const handleResetDraw = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await resetDraw(draw.id);
      if (res?.data?.error === false) {
        toast.success(res.data.data);
      }
      if (res?.error?.data?.error !== undefined) {
        toast.success(res.error.data.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refetch();
  }, [eventId, deleteDraw]);

  const renderFinalizeModal = () => {
    let error = null;
    if (!draw.finalized) {
      if (draw?.drawFormat === "RR") {
        error = checkClubConflictRR();
      } else {
        error = checkClubConflictSE();
      }
    }
    return (
      <div
        className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay"
        onClick={() => setConfirmFinalize(false)}
      >
        <div
          className="bg-white rounded-lg p-5"
          onClick={(e) => e.stopPropagation()} // Prevent click propagation
        >
          <div className="text-lg text-gray-800 text-center font-bold">
            Are you sure?
          </div>
          <div className="text-xs text-gray-500 text-center max-w-md mt-5">
            {draw?.finalized
              ? "All the current Match data and Results will be lost for this draw."
              : "The Draw settings will be finalized and Event Matches will start."}
          </div>
          {!draw?.finalized && (
            <>
              {error && (
                <div className="text-red-500 text-center font-medium text-sm mt-3">
                  {error}
                </div>
              )}
              {unassigned && unassigned.length > 0 && (
                <div className="text-red-500 text-center font-medium text-sm mt-3">
                  Some New Players are not included in the draw yet!
                </div>
              )}
            </>
          )}
          <div className="flex justify-center mt-10 gap-10 text-white font-medium">
            <button
              className="text-sm px-5 py-2 rounded bg-gray-400 hover:bg-gray-500 border"
              onClick={() => setConfirmFinalize(false)}
            >
              Cancel
            </button>
            <button
              className={`text-sm px-5 py-2 rounded ${
                draw?.finalized
                  ? "bg-red-600 hover:bg-red-700"
                  : "bg-green-600 hover:bg-green-700"
              } border`}
              onClick={finalizeDraw}
              disabled={finalizing} // Disable button if loading
            >
              {finalizing
                ? "Finalizing..."
                : error
                ? "Confirm Anyway"
                : "Confirm"}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {(isFetching || addingPlayerRR || addingPlayerSE) && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay">
          <div className="  relative mx-3">
            <img className="" src={loadingImg} alt="" />
          </div>
        </div>
      )}
      {drawForm ? (
        <AddDrawForm
          handleCloseForm={handleDrawForm}
          handleDeleteForm={handleDeleteDraw}
          event={event}
          tournament={tournament}
          draw={draw}
          finalized={draw?.finalized}
        />
      ) : (
        <>
          {confirmFinalize && renderFinalizeModal()}
          {confirmActivate && (
            <div
              className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay"
              onClick={() => setConfirmActivate(false)}
            >
              <div
                className="bg-white rounded-lg p-5"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="text-lg text-gray-800 text-center font-bold">
                  Are you sure?
                </div>
                <div className="text-xs text-gray-500 text-center max-w-md">
                  {"("}Knockout Stage is not started yet! Activate to Start,
                  Once Stated cannot go back to Group Stage{")"}
                </div>
                <div className="flex justify-center mt-10 gap-10 text-white font-medium">
                  <button
                    className="text-sm px-5 py-2 rounded bg-gray-400 hover:bg-gray-500 border"
                    onClick={() => setConfirmActivate(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="text-sm px-5 rounded py-2 bg-green-600 hover:bg-green-700 border"
                    onClick={handleActivateKnockout}
                  >
                    {loading ? "loading.." : "Confirm"}
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col items-start justify-start w-full mt-8">
            {draw?.id ? (
              <div className="w-full">
                <div className="flex justify-between">
                  <div className="flex items-start gap-2 mt-5 mb-10">
                    <button onClick={() => setDrawForm(true)} className="mt-1">
                      <SquaresPlusIcon height={20} width={20} />
                    </button>

                    <div className=" ">
                      <div className="flex items-center gap-3">
                        <div className="text-lg font-bold">
                          {draw?.drawName}
                        </div>
                      </div>
                      <div className="flex items-center gap-1 mt-2">
                        <div className="text-xs font-medium text-gray-600">
                          Estemated Start: {event?.startDate?.split("T")[0]},{" "}
                        </div>
                        <div className="text-xs font-medium text-gray-600">
                          {" "}
                          {event?.startTime}
                        </div>
                      </div>
                      <div className="flex items-center gap-1 mt-1">
                        <div className="text-xs font-medium text-gray-600">
                          Format:{" "}
                          {draw?.drawFormat === "RR"
                            ? "Round-Robin"
                            : "Single Elimination"}
                        </div>
                      </div>
                      <div className="flex items-center gap-1 mt-1">
                        <div className="text-xs font-medium text-gray-600">
                          First to {draw?.defaultMatchFormat?.pointsPerGame}{" "}
                          (Win by {draw?.defaultMatchFormat?.deuce ? "2" : "1"}{" "}
                          ) <span className="text-sm font-black">.</span> Best
                          of {draw?.defaultMatchFormat?.gamesPerMatch}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => setConfirmFinalize(true)}
                      className={` ${
                        draw?.finalized ? "bg-red-600" : "bg-blue-700"
                      } rounded shadow border text-white text-xs sm:text-sm px-3 py-2`}
                    >
                      {draw?.finalized ? "Undo Draw" : "Finalize Draw"}
                    </button>
                  </div>
                </div>
                <div>
                  <div className="space-y-0.5">
                    {unassigned &&
                      ((!draw?.finalized && draw.drawFormat === "SE") ||
                        (draw?.drawFormat === "RR" &&
                          !draw?.roundRobinSettings?.activateSE)) &&
                      unassigned.map((player, playerIndex) => (
                        <div
                          key={playerIndex}
                          className={`flex justify-between items-center border shadow rounded-md px-3 py-1 bg-gray-300`}
                        >
                          <div className="bg-white min-w-56 rounded p-1">
                            {player && (
                              <div className="text-[10px] text-gray-500 flex gap-1">
                                <div className="">{player?.rating || ""}</div>
                                {player?.club && (
                                  <>
                                    <span className="font-bold text-black text-xs -mt-0.5">
                                      .
                                    </span>
                                    <div className="text-[10px] text-gray-500 font-medium">
                                      {player?.club?.clubName},{" "}
                                      {player?.club?.region},{" "}
                                      {player?.club?.state}
                                    </div>
                                  </>
                                )}
                              </div>
                            )}

                            <div className="text-sm font-medium text-gray-700">
                              {player
                                ? `${player.firstName}, ${player.lastName}`
                                : "TBD"}
                            </div>
                          </div>
                          <button
                            onClick={() => {
                              setAddPlayer(!addPlayer);
                              setIndex(playerIndex);
                            }}
                            className="px-3 bg-white hover:bg-gray-100 rounded py-2 border border-black flex items-center"
                          >
                            Add Player <ChevronDownIcon className="h-5 w-5" />
                          </button>
                          {addPlayer && playerIndex === index && (
                            <div className="absolute bg-gray-50 p-3 border rounded shadow right-8 mt-4">
                              {draw?.drawFormat === "RR" ? (
                                <div className="grid space-y-1">
                                  {draw?.groups
                                    ?.filter((g) => g.type === "RR")
                                    .map((group, i) => (
                                      <button
                                        key={i}
                                        onClick={() =>
                                          handleNewPlayerRR(group.id, {
                                            player,
                                            tournamentId,
                                            eventId,
                                            finalized: draw.finalized,
                                          })
                                        }
                                        className="grid bg-gray-200 hover:bg-gray-300 px-3 rounded py-1"
                                      >
                                        Group {group?.number + 1}
                                      </button>
                                    ))}
                                </div>
                              ) : (
                                <div className="grid space-y-1">
                                  {draw?.groups
                                    ?.find((group) => group.number === 0)
                                    ?.groupPlayers?.filter(
                                      (player) => player.player === null
                                    )
                                    .sort((a, b) => a.order - b.order)
                                    ?.map((place, i) => (
                                      <button
                                        key={i}
                                        onClick={() =>
                                          handleNewPlayerSE(
                                            draw.groups.find(
                                              (group) => group.number === 0
                                            )?.id,
                                            {
                                              place,
                                              player,
                                            }
                                          )
                                        }
                                        className="grid bg-gray-200 hover:bg-gray-300 px-3 rounded py-1"
                                      >
                                        Place {place?.order + 1}
                                      </button>
                                    ))}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                  <div className="flex justify-center mt-5">
                    {((!draw?.finalized && draw.drawFormat === "SE") ||
                      (draw?.drawFormat === "RR" &&
                        !draw?.roundRobinSettings?.activateSE)) && unassigned?.length > 0 && !draw?.finalized && (
                      <button
                        onClick={handleResetDraw}
                        className={`   bg-green-600 rounded shadow hover:shadow-lg border text-white px-3 py-2`}
                      >
                        Auto Add All Players
                      </button>
                    )}
                  </div>
                </div>
                <div className="my-5 flex gap-3 text-xs sm:text-sm">
                  {!draw?.finalized && (
                    <>
                      <button
                        onClick={handleResetDraw}
                        className={` border-blue-600 rounded shadow hover:shadow-lg border text-blue-700 px-3 py-2`}
                      >
                        Reset to Default
                      </button>
                      <button
                        onClick={() => setSeeding(!seeding)}
                        className={`   border-blue-600 rounded shadow hover:shadow-lg border text-blue-700 px-3 py-2`}
                      >
                        Seeding Rules {seeding ? "On" : "Off"}
                      </button>
                    </>
                  )}
                </div>

                {draw?.drawFormat === "RR" ? (
                  <>
                    {draw?.finalized ? (
                      <div className="w-full">
                        <div className="w-full flex gap-3 justify-center text-xs sm:text-sm mb-10">
                          <button
                            onClick={() => setStage(false)}
                            className={`font-medium px-3 py-3 rounded text-white ${
                              !stage ? "bg-blue-800" : "bg-gray-300"
                            }`}
                          >
                            Group Stage
                          </button>
                          <button
                            onClick={() => setStage(true)}
                            className={`font-medium px-3 py-3 rounded text-white ${
                              stage ? "bg-blue-800" : "bg-gray-300"
                            }`}
                          >
                            Knockout Stage
                          </button>
                        </div>
                        {!stage ? (
                          <div className="w-full grid grid-col-1 md:grid-cols-2 gap-1  lg:gap-5">
                            {draw?.groups &&
                              draw?.groups?.length &&
                              draw.groups
                                .filter((group) => group.type === "RR")
                                .slice()
                                .sort((a, b) => a.number - b.number)
                                .map((group, i) => (
                                  <GroupCard
                                    group={group}
                                    roundRobbin={draw?.roundRobinSettings}
                                    key={i}
                                    admin={true}
                                  />
                                ))}
                          </div>
                        ) : (
                          <div>
                            {!draw?.roundRobinSettings?.activateSE && (
                              <div className="w-full grid grid-cols-3 gap-10 items-center mb-5">
                                <div></div>
                                <div className="text-xs text-gray-500 text-center">
                                  {"("}Knockout Stage is not started yet!
                                  Activate to Start, Once Stated cannot go back
                                  to Group Stage{")"}
                                </div>
                                <button
                                  onClick={() => setConfirmActivate(true)}
                                  className="px-2 py-3 rounded bg-green-600 hover:bg-green-700 text-xs sm:text-sm font-medium text-white"
                                >
                                  Activate Knockout
                                </button>
                              </div>
                            )}

                            <FinalizedSingleElimination
                              drawId={draw?.id}
                              groups={draw?.groups.filter(g=>g.type==="SE")}
                              admin={true}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <>
                        <RoundRobin draw={draw} seeding={seeding} />
                      </>
                    )}{" "}
                  </>
                ) : (
                  <div>
                    {draw?.finalized ? (
                      <FinalizedSingleElimination
                        drawId={draw?.id}
                        groups={draw?.groups.filter(g=>g.type==="SE")}
                        admin={true}
                      />
                    ) : (
                      <>
                        <SingleElimination draw={draw} seeding={seeding} />
                      </>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="w-full flex justify-center">
                <div className="text-center mt-20 text-gray-500">
                  No Draw Created Yet!
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default DrawsTab;
