import React, { useEffect, useState } from "react";
import SlidePanel from "../../../components/commons/slidePanel";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MatchesTab from "../../../components/sections/tabs/MatchesTab";
import { classNames } from "../../../utils/classNames";
import Draws from "../../../components/sections/draws/Draws";
import { useSelector } from "react-redux";
import ManagePlayers from "../../../components/sections/mangePlayers/ManagePlayers";
import {
  useGetEventByIdQuery,
  useGetTournamentByIdQuery,
  useLazyGetEventEntriesQuery,
  useLazyGetEventEntriesUserQuery,
  useLazyGetQueryEventsByTournamentIdQuery,
  useSelfRegistrationMutation,
} from "../../../redux/features/api/apiSlice";
import AddPlayerForm from "../Players/AddPlayerForm";
import UserPlayersTab from "../../../components/sections/tabs/UserPlayersTab";
import loadingImg from "../../../images/loading-image.svg";
import { toast } from "react-toastify";
import UserDrawsTab from "../../../components/sections/tabs/UserDrawsTab";

const sidePanelConfigs = {
  draws: {
    title: "Make New Draw",
    description:
      "Lorem ipsum dolor ut labore et  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    component: (setOpen) => <Draws />,
  },
  players: {
    title: "Manage Players",
    description: "Following Players are Eligible for the Event.",
    component: (setOpen) => <ManagePlayers />,
  },
};

const EventPage = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  let { eventId, tournamentId } = useParams();
  const [selfRegistration] = useSelfRegistrationMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const { isFetching, refetch: refetchEvent } = useGetEventByIdQuery(eventId);
  const [playerForm, setPlayerForm] = useState(false);
  const [selfRegister, setSelfRegister] = useState(false);
  const { refetch: refetchTournament } =
    useGetTournamentByIdQuery(tournamentId);
  const { tournament } = useSelector((state) => state.tournaments);

  const { queryEvents } = useSelector((state) => state.events);
  const { event } = useSelector((state) => state.events);
  const [fetchEntries] = useLazyGetEventEntriesUserQuery();
  const { eventEntriesUser } = useSelector((state) => state.entries);
  const [fetchQueryEvents] = useLazyGetQueryEventsByTournamentIdQuery();

  useEffect(() => {
    if (tournament && tournament.id === tournamentId) {
      fetchQueryEvents({ tournamentId, query: "published" });
      fetchEntries(eventId)
    }
  }, [tournament, tournamentId, fetchQueryEvents, fetchEntries]);

  const tabs = [
    {
      name: "draws",
      href: "#draws",
      element: () => <UserDrawsTab event={event} />,
    },
    {
      name: "matches",
      href: "#matches",
      element: () => (
        <MatchesTab
          tournamentData={tournament}
          event={true}
          tournament={false}
          player={false}
        />
      ),
    },
    {
      name: "players",
      href: "#players",
      element: () => <UserPlayersTab eventEntries={eventEntriesUser} tournament={false} event={true} />,
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  const handleActiveTabChange = (tab) => {
    setActiveTab(tab.name);
    navigate(tab.href);
  };

  const handlePlayerForm = () => {
    setPlayerForm(false);
  };

  const handleSelfRegistration = async () => {
    if (currentUser?.id) {
      if (currentUser?.player?.id) {
        try {
          if (loading) return;
          setLoading(true);
          const res = await selfRegistration({
            data: {
              eventId: event.id,
              tournament,
              notes: { selfRegistered: true },
            },
            id: currentUser.player.id,
          });
          if (!res.error) {
            toast.success("Registration Successfull");
          }
          if (res.error) {
            toast.error(res.error.data.error);
          }
        } catch (err) {
          console.log(err);
          toast.error(err.error.data.error);
        } finally {
          setLoading(false);
          setSelfRegister(false)
        }
      } else {
        toast.info("complete your player profile to self-register.");
      }
    } else {
      toast.info("Please log in to self-register.");
    }
  };

  useEffect(() => {
    if (!location.hash) {
      setActiveTab(tabs[0].name);
    } else {
      const currentTab = tabs.find((tab) => tab.href === location.hash);
      if (currentTab) {
        setActiveTab(currentTab.name);
      }
    }
  }, [location.hash]);

  useEffect(() => {
    refetchTournament();
    refetchEvent();
  }, [eventId]);

  if (isFetching)
    return (
      <div className=" h-screen w-full flex justify-center items-center">
        <img className="-mt-40" src={loadingImg} alt="" />
      </div>
    );

  return (
    <>
      {playerForm ? (
        <AddPlayerForm
          handleCloseForm={handlePlayerForm}
          info={{ queryEvents, tournament }}
        />
      ) : selfRegister ? (
        <div
          className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay"
          onClick={() => setSelfRegister(false)}
        >
          <div
            className="bg-white rounded-lg p-5"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="text-lg text-gray-800 text-center font-bold">
              Confirm Registeration?
            </div>
            <div className="text-xs text-gray-500 text-center max-w-md mt-5">
              You will become a participent in this event, confirm your registration.
            </div>
            <div className="flex justify-center mt-10 gap-10 text-white font-medium">
              <button
                className="text-sm px-5 py-2 rounded bg-gray-400 hover:bg-gray-500 border"
                onClick={() => setSelfRegister(false)}
              >
                Cancel
              </button>
              <button
                className={`text-sm px-5 rounded py-2 bg-green-600 hover:bg-green-700 border`}
                onClick={handleSelfRegistration}
              >
                {loading ? 'loading..' : 'Register'}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          {Object.keys(sidePanelConfigs).includes(activeTab) && (
            <SlidePanel
              config={{ open, setOpen }}
              section={sidePanelConfigs[activeTab]}
              eventId={eventId}
            />
          )}
          <div className="flex items-start gap-2 mt-5 mb-10">
            <div className=" px-3">
              <div className="flex items-center gap-3">
                <div className="text-lg font-bold">
                  {tournament?.tournamentName}
                </div>
                <div className="flex items-center font-medium uppercase text-[10px] px-2 h-5 bg-blue-600 text-white rounded-sm">
                  {tournament?.status}
                </div>
              </div>
              <div className="flex items-center gap-1 mt-2">
                <div className="text-xs font-medium text-gray-500">
                  {tournament?.startDate?.split("T")[0]} -{" "}
                </div>
                <div className="text-xs font-medium text-gray-500">
                  {" "}
                  {tournament?.endDate?.split("T")[0]}
                </div>
              </div>
              <div className="flex items-center gap-1 mt-1">
                <div className="text-xs font-medium text-gray-500">
                  ${tournament?.tournamentFee}
                </div>
              </div>
              <div className="flex items-center gap-1 mt-1">
                <div className="text-xs font-medium text-gray-500">
                  {tournament?.location}
                </div>
              </div>
            </div>
          </div>
          <div className="my-5 px-3">
            <div className="flex items-center gap-3">
              <div className="text-lg font-bold">{event.eventName}</div>
              <div
                className={`flex items-center font-medium uppercase text-[10px] px-2 h-5 ${
                  event.entry ? "bg-blue-600" : "bg-gray-400"
                }  text-white rounded-sm`}
              >
                {event.entry ? "OPEN" : "CLOSED"}
              </div>
            </div>
            <div className="flex items-center gap-1 mt-2">
              <div className="text-xs font-medium text-gray-500">
                {event?.startDate?.split("T")[0]} -{" "}
              </div>
              <div className="text-xs font-medium text-gray-500">
                {" "}
                {event?.startTime}
              </div>
            </div>
            <div className="flex items-center gap-1 mt-1">
              <div className="text-xs font-medium text-gray-500">
                Event Fee:{" "}
                <span className="text-black font-medium">
                  ${event?.eventFee}
                </span>
              </div>
            </div>
            <div className="flex items-center gap-1 mt-1">
              <div className="text-xs font-medium text-gray-400">
                Max Rating: {event?.entryRestrictions?.maxRating}
              </div>
            </div>
            <div className="flex items-center gap-1 mt-1">
              <div className="text-xs font-medium text-gray-400">
                Max DOB: {event?.entryRestrictions?.minDOB}
              </div>
            </div>
          </div>
          <div className="relative pb-5 border-b border-gray-200 sm:pb-0 px-3 ">
            <div className="lg:flex lg:items-center lg:justify-between">
              {event?.entry &&
                tournament?.customSettings?.enableSelfRegistrations && (
                  <div className="flex">
                    <div className="flex mt-3 lg:absolute lg:right-0 md:top-3 lg:mt-0 gap-10">
                      {!eventEntriesUser?.some(
                        (entry) => entry.playerId === currentUser?.player?.id
                      ) && (
                        <button
                          disabled={loading}
                          onClick={()=>setSelfRegister(true)}
                          className="inline-flex items-center border border-blue-700 bg-blue-700 px-1 sm:px-3 py-2 text-[11px] sm:text-sm font-semibold  text-white rounded-md hover:shadow hover:bg-blue-600"
                        >
                          {loading ? "loading.." : " Self Registration"}
                        </button>
                      )}
                      <button
                        onClick={() => setPlayerForm(true)}
                        type="button"
                        className="inline-flex items-center border border-blue-700 bg-blue-700 px-1 sm:px-3 py-2 text-[11px] sm:text-sm font-semibold  text-white rounded-md hover:shadow hover:bg-blue-600"
                      >
                        Entry Form
                      </button>
                    </div>
                  </div>
                )}
            </div>
            <div className="mt-8">
              <div className="">
                <nav className="flex -mb-px space-x-4 sm:space-x-8">
                  {tabs.map((tab) => (
                    <button
                      onClick={() => handleActiveTabChange(tab)}
                      key={tab.name}
                      className={classNames(
                        tab.name === activeTab
                          ? "border-indigo-500 text-indigo-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 px-1 pb-4 text-xs sm:text-sm font-medium capitalize"
                      )}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div>
            {tabs.map((tab, i) => {
              if (tab.name === activeTab) {
                return (
                  <div className="px-3" key={i}>
                    {tab.element()}
                  </div>
                );
              }
              return null;
            })}
          </div>
        </>
      )}
    </>
  );
};

export default EventPage;
