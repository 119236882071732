import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";


export const initialState = {
  match:{},
  matches: [],
  playerMatches:[],
  eventMatches:[],
  tournamentMatches:[]
};

export const matchSlice = createSlice({
  name: "match",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getGroupMatches.matchFulfilled,
      (state, action) => {
        state.matches = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getPlayerMatches.matchFulfilled,
      (state, action) => {
        state.playerMatches = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getEventMatches.matchFulfilled,
      (state, action) => {
        state.eventMatches = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getMatchByTournamentId.matchFulfilled,
      (state, action) => {
        state.tournamentMatches = action.payload.data;
      }
    );
  },
});

export default matchSlice.reducer;
